import React from "react"

import "./scrollPositionIndicatorPixel.scss"

interface IScrollPositionIndicatorPixel {
  handleBarHeight: number
  handleBarPosition: number
}
const ScrollPositionIndicatorPixel: React.FC<IScrollPositionIndicatorPixel> = (props) => {
  const handleStyle = {
    height: props.handleBarHeight + "px",
    transform: "translateY(" + props.handleBarPosition + "px)"
  }

  return (
    <div className="scrollposition-indicator__wrapper">
      <div className="scrollposition-indicator__rail"></div>
      <div style={handleStyle} className="scrollposition-indicator__handle"></div>
    </div>
  )
}

export default ScrollPositionIndicatorPixel
