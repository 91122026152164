import React from "react"
import ClassNames from "classnames"

import Icon from "@pag/center/components/icon/Icon"
import NonI18nLabel from "@pag/center/components/nonI18nLabel/NonI18nLabel"
import DistanceDestination from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/distanceDestination/DistanceDestination"
import { naviDistanceMetric } from "@pag/utils/navigationService/navigationUtils"

import { LocationObject } from "../types"

import "./mapTooltipAddressItem.scss"

interface IMapTooltipAddressItem {
  className?: string
  address: LocationObject
  distance?: number
  onPress: any
}

const MapTooltipAddressItem: React.FC<IMapTooltipAddressItem> = (props: IMapTooltipAddressItem) => (
  <div className={ClassNames("map-tooltip-address-item-wrapper", props.className)} onClick={props.onPress}>
    <NonI18nLabel
      className="address__text"
      text={props.address.addressLineFirst} // TODO:
    />
    <div className="rrd">
      {props.distance === undefined ? (
        <Icon iconClass="rrd__loading-spinner" iconType="icon-processing state-rotate-right" />
      ) : (
        <DistanceDestination formattedDistance={naviDistanceMetric(props.distance)} />
      )}
    </div>
  </div>
)

export default MapTooltipAddressItem
