import * as React from "react"

import InfoMessage from "@pag/center/components/infoMessage/InfoMessage"
import { IconType } from "@pag/center/components/icon/iconType"
import Button from "@pag/center/components/button/Button"
import ScrollPanel from "@pag/center/components/scrollPanel/ScrollPanel"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"

import "./update.scss"
import { ROUTES as ROUTES_MAIN } from "@pag/center/views/routes"

export enum EEntryActions {
  NONE = "none",
  CHECKBOX = "checkbox",
  RADIOBUTTON = "radioButton",
  SLIDER = "slider",
  LINK = "link",
  RADIOBUTTON_SLIDEOUT = "radiobutton_slideout",
  SLIDEOUT = "slideout",
  EDITABLE_CHECKBOX = "editable_checkbox",
  TIME_PICKER_SLIDEOUT = "time_picker_slideout",
  DATE_PICKER_SLIDEOUT = "date_picker_slideout",
  MOVE = "move",
  POPUP = "popup",
  SWITCH = "switch",
  MORE_BUTTON = "more_button"
}

/*------------------------------MAIN SETTINGS------------------------------*/

export const settings_MainList = {
  name: "Settings_Main",
  icon: "icon-setting",
  entries: [
    { label: "Settings_Main_System", linkto: "settings_SystemList" },
    { label: "Settings_Main_Displays", linkto: "settings_DisplaysList" },
    { label: "Settings_Main_Volume", linkto: "settings_VolumeList" },
    { label: "Settings_Main_Sound", linkto: "settings_SoundList" },
    { label: "Settings_Main_VoiceControl", linkto: "settings_VoiceControlList" },
    { label: "Settings_Main_Vehicle", linkto: "settings_VehicleList_Settings" },
    { label: "Settings_Main_AssistanceSystems", linkto: "settings_AssistanceSystemsList" },
    { label: "Settings_Main_SportChrono", linkto: "Settings_Main_SportChronoList" },
    { label: "Settings_Main_NotificationCenter", linkto: "settings_NotificationCenterList" },
    { label: "Settings_Main_Radio", linkto: "settings_RadioList" },
    { label: "Settings_Main_PrivacyAndPorscheConnect", linkto: "settings_PrivacyAndPorscheConnectList" },
    // { label: "Settings_Main_Phone", linkto: "settings_PhoneList" },
    // { label: "Settings_Main_DeviceManager", linkto: "settings_DeviceManagerList" },
    // { label: "Settings_Main_Navigation", linkto: "settings_NavigationList_Settings" },
    { label: "Settings_Main_Account", linkto: "settings_AccountList" },
    { label: "Settings_Main_SetUpAssistant", action: EEntryActions.LINK },
    { label: "Settings_Main_SoftwareInformation", linkto: "settings_SoftwareInformationList" }
    // { label: "Settings_Main_HomeLink", linkto: "settings_HomeLinkList_Settings" }
  ]
}

export const settings_MainList_Passenger = {
  name: "Settings_Main",
  entries: [
    { label: "Settings_Display_PassengerDisplay", linkto: "Settings_Display_PassengerDisplayList" },
    { label: "Settings_Main_Phone", linkto: "settings_PhoneList" },
    { label: "Settings_Main_DeviceManager", linkto: "settings_DeviceManagerList" },
    { label: "Settings_Main_Sound", linkto: "settings_SoundList" }
  ]
}

/*------------------------------SYSTEM SETTINGS------------------------------*/

export const settings_SystemList = {
  name: "Settings_System",
  entries: [
    { label: "Settings_System_LanguageAndKeyboard", linkto: "Settings_System_LanguageAndKeyboardList" },
    { label: "Settings_System_DateAndTime", linkto: "Settings_System_DateAndTimeList" },
    { label: "Settings_System_Units", linkto: "Settings_System_UnitsList" },
    { label: "Settings_System_ResetToFactory", action: EEntryActions.NONE }
  ]
}

export const Settings_System_LanguageAndKeyboardList = {
  name: "Settings_System_LanguageAndKeyboard",
  entries: [
    { label: "Settings_System_LanguageAndKeyboard_Language", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_System_LanguageAndKeyboard_Keyboard", action: EEntryActions.SLIDEOUT }
  ]
}

export const Settings_System_DateAndTimeList = {
  name: "Settings_System_DateAndTime",
  entries: [
    {
      label: "Settings_System_DateAndTime_AutomaticallySetDateAndTime",
      action: EEntryActions.CHECKBOX,
      selected: false
    },
    { label: "Settings_System_DateAndTime_SetTimeManually", action: EEntryActions.TIME_PICKER_SLIDEOUT },
    { label: "Settings_System_DateAndTime_SetDateManually", action: EEntryActions.DATE_PICKER_SLIDEOUT },
    { label: "Settings_System_DateAndTime_SetTimeZoneAutomatically", action: EEntryActions.CHECKBOX, selected: true },
    {
      label: "Settings_System_DateAndTime_SetTimeZoneManually",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT,
      disabled: true
    },
    { label: "Settings_System_DateAndTime_SetTimeFormat", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_System_DateAndTime_SetDateFormat", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

export const Settings_System_UnitsList = {
  name: "Settings_System_Units",
  entries: [
    { label: "Settings_System_Units_SpeedAndDistance", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_System_Units_Temperature", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_System_Units_Pressure", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_System_Units_Consumption", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

/*------------------------------DISPLAY SETTINGS------------------------------*/

export const settings_DisplaysList = {
  name: "Settings_Display",
  decoratorClass: "content__displaysettings--lhd-nochrono",
  entries: [
    { label: "Settings_Display_CentralDisplay", linkto: "Settings_Display_CentralDisplayList" },
    {
      label: "Settings_Display_CenterConsoleOperatingPanel",
      linkto: "Settings_Display_CenterConsoleOperatingPanelList"
    },
    // { label: "Settings_Display_HUD", linkto: "Settings_Display_HUDList" },
    { label: "Settings_Display_InstrumentCluster", linkto: "Settings_Display_InstrumentClusterList" },
    { label: "Settings_Display_PassengerDisplay", linkto: "Settings_Display_PassengerDisplayList" }
    // { label: "Settings_Display_AdditionalInstrument", linkto: "Settings_Display_AdditionalInstrumentList" }
  ]
}

export const Settings_Display_CentralDisplayList = {
  name: "Settings_Display_CentralDisplay",
  decoratorClass: "content__centerdisplay--lhd-nochrono",
  entries: [
    { label: "Settings_Display_CentralDisplay_Brightness", action: EEntryActions.SLIDER },
    { label: "Settings_Display_CentralDisplay_ProximitySensors", action: EEntryActions.CHECKBOX, selected: false },
    { label: "Settings_Display_CentralDisplay_TouchTones", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_CentralDisplay_TurnOffCenterDisplay", action: EEntryActions.NONE }
  ]
}

export const Settings_Display_HUDList = {
  name: "Settings_Display_HUD",
  decoratorClass: "",
  entries: [
    { label: "Settings_Display_HUD_TurnOnDisplay", action: EEntryActions.CHECKBOX },
    { label: "Settings_Display_HUD_CustomisedView", action: EEntryActions.NONE },
    { label: "Settings_Display_HUD_View", action: EEntryActions.SLIDEOUT, showNumberOfCheckedEntries: true },
    { label: "Settings_Display_HUD_IncomingCalls", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_HUD_TrafficSign", linkto: "Settings_Display_HUD_TrafficSignList" },
    { label: "Settings_Display_HUD_DayAndNightView", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
    // { label: "Settings_Display_HUD_LicenseInfo", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_Display_HUD_TrafficSignList = {
  name: "Settings_Display_HUD_TrafficSign",
  entries: [
    { label: "Settings_Display_HUD_TrafficSign_ShowInHUD", action: EEntryActions.CHECKBOX },
    { label: "Settings_Display_HUD_TrafficSign_SpeedingWarning", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_Display_HUD_TrafficSign_Deviation",
      secondLine: "Settings_Display_HUD_TrafficSign_DeviationLine2",
      action: EEntryActions.SLIDER
    }
  ]
}
export const Settings_Display_CenterConsoleOperatingPanelList = {
  name: "Settings_Display_CenterConsoleOperatingPanel",
  decoratorClass: "content__tbf--lhd-nochrono",
  entries: [
    { label: "Settings_Display_CenterConsoleOperatingPanel_Brightness", action: EEntryActions.SLIDER },
    { label: "Settings_Display_CenterConsoleOperatingPanel_TouchTones", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    // { label: "Settings_Display_CenterConsoleOperatingPanel_HapticFeedback", action: EEntryActions.NONE },
    {
      label: "Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    }
    // { label: "Settings_Display_CenterConsoleOperatingPanel_QuickLinks", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_Display_AdditionalInstrumentList = {
  name: "Settings_Display_AdditionalInstrument",
  entries: [
    { label: "Settings_Display_AdditionalInstrument_Brightness", action: EEntryActions.SLIDER },
    { label: "Settings_Display_AdditionalInstrument_Clock", action: EEntryActions.CHECKBOX },
    { label: "Settings_Display_AdditionalInstrument_Illumination", action: EEntryActions.CHECKBOX },
    { label: "Settings_Display_AdditionalInstrument_Compass", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_Display_InstrumentClusterList = {
  name: "Settings_Display_InstrumentCluster",
  decoratorClass: "content__ic--lhd-nochrono",
  entries: [
    {
      label: "Settings_Display_InstrumentCluster_ConfigureDisplayScope",
      action: EEntryActions.SLIDEOUT,
      showNumberOfCheckedEntries: true
    },
    {
      label: "Settings_Display_InstrumentCluster_CustomisedView",
      linkto: "Settings_Display_InstrumentCluster_CustomisedViewList"
    },
    { label: "Settings_Display_InstrumentCluster_Brightness", action: EEntryActions.SLIDER },
    {
      label: "Settings_Display_InstrumentCluster_PersonalSpeedLimits",
      linkto: "Settings_Display_InstrumentCluster_PersonalSpeedLimitsList"
    },
    {
      label: "Settings_Display_InstrumentCluster_TrafficSignRecognition",
      linkto: "Settings_Display_InstrumentCluster_TrafficSignRecognitionList"
    },
    {
      label: "Settings_Display_InstrumentCluster_HazardousSituations",
      action: EEntryActions.CHECKBOX,
      selected: false
    },
    { label: "Settings_Display_InstrumentCluster_ShowIncomingCalls", action: EEntryActions.CHECKBOX, selected: false }
  ]
}

export const Settings_Display_InstrumentCluster_CustomisedViewList = {
  name: "Settings_Display_InstrumentCluster_CustomisedView",
  entries: [
    { label: "Settings_Display_InstrumentCluster_CustomisedView_Line1", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_InstrumentCluster_CustomisedView_Line2", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_InstrumentCluster_CustomisedView_Line3", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_InstrumentCluster_CustomisedView_Line4", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

export const Settings_Display_InstrumentCluster_PersonalSpeedLimitsList = {
  name: "Settings_Display_InstrumentCluster_PersonalSpeedLimits",
  entries: [
    {
      label: "Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit1",
      secondLine: "Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit_SecondLine",
      action: EEntryActions.EDITABLE_CHECKBOX,
      secondLineInterpolationKey: "speed",
      selected: false
    },
    {
      label: "Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit2",
      secondLine: "Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit_SecondLine",
      secondLineInterpolationKey: "speed",
      action: EEntryActions.EDITABLE_CHECKBOX,
      selected: false
    }
  ]
}

export const Settings_Display_InstrumentCluster_TrafficSignRecognitionList = {
  name: "Settings_Display_InstrumentCluster_TrafficSignRecognition",
  decoratorClass: "",
  entries: [
    {
      label: "Settings_Display_InstrumentCluster_TrafficSignRecognition_Warn",
      action: EEntryActions.CHECKBOX,
      selected: false
    },
    {
      label: "Settings_Display_InstrumentCluster_TrafficSignRecognition_Deviation",
      secondLine: "Settings_Display_InstrumentCluster_TrafficSignRecognition_DeviationLine2",
      action: EEntryActions.SLIDER
    }
  ]
}

export const Settings_Display_PassengerDisplayList = {
  name: "Settings_Display_PassengerDisplay",
  decoratorClass: "content__cdd--lhd-nochrono",
  entries: [
    { label: "Settings_Display_CenterConsoleOperatingPanel_Brightness", action: EEntryActions.SLIDER },
    { label: "Settings_Display_CentralDisplay_ProximitySensors", action: EEntryActions.CHECKBOX, selected: false },
    // { label: "Settings_Display_PassengerDisplay_DisplayInStandbyMode", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Display_PassengerDisplay_StandbyMode", action: EEntryActions.NONE },
    { label: "Settings_Display_PassengerDisplay_TurnOnDisplay", action: EEntryActions.CHECKBOX, selected: true }
  ]
}

/*------------------------------PHONE SETTINGS------------------------------*/

export const settings_PhoneList = {
  name: "Settings_Phone",
  icon: "icon-phone_receiver",
  entries: [
    { label: "Settings_Phone_GeneralSettings", linkto: "Settings_Phone_GeneralSettingsList" },
    { label: "Settings_Phone_PhoneBookSettings", linkto: "Settings_Phone_PhoneBookSettingsList" }
  ]
}

export const Settings_Phone_GeneralSettingsList = {
  name: "Settings_Phone_General",
  icon: "icon-phone_receiver",
  entries: [
    {
      label: "Settings_Phone_GeneralSettings_Checkbox_WirelessChargingLabel",
      action: EEntryActions.CHECKBOX,
      selected: false
    },
    { label: "Settings_Phone_General_MailboxNumber", action: EEntryActions.NONE },
    { label: "Settings_Phone_General_SerialNumber", action: EEntryActions.NONE }
  ]
}

export const Settings_Phone_PhoneBookSettingsList = {
  name: "Settings_Phone_PhoneBookSettings",
  icon: "icon-phone_receiver",
  entries: [
    { label: "Settings_Phone_PhoneBookSettings_ContactMemory", end: "21/1000", action: EEntryActions.NONE },
    { label: "Settings_Phone_PhoneBookSettings_SortingCriteria", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    {
      label: "Settings_Phone_PhoneBookSettings_UpdateContacts",
      secondLine: "Settings_Phone_General_UpdateContacts_SecondLine",
      action: EEntryActions.LINK
    }
  ]
}

/*------------------------------MESSAGES SETTINGS------------------------------*/

export const Settings_Messages = {
  name: "Settings_Options",
  icon: IconType.icon_message,
  entries: [
    { label: "Settings_Phone_GeneralSettings", linkto: "Settings_Phone_GeneralSettingsList" },
    // { label: "Settings_Phone_PhoneBookSettings", linkto: "Settings_Phone_PhoneBookSettingsList" },
    {
      label: "Settings_Main_DeviceManager",
      action: EEntryActions.LINK,
      link: ROUTES_MAIN.DEVICES
    }
  ]
}

/*------------------------------DEVICE MANAGER SETTINGS------------------------------*/

export const settings_DeviceManagerList = {
  name: "Settings_DeviceManager",
  entries: [
    { label: "Settings_DeviceManager_TelephoneSettings", linkto: "Settings_Phone_GeneralSettingsList" },
    { label: "Settings_DeviceManager_BluetoothSettings", linkto: "Settings_DeviceManager_BluetoothSettingsList" }
    // { label: "Settings_DeviceManager_WiFiSettings", linkto: "Settings_DeviceManager_WiFiSettingsList" },
    // {
    //   label: "Settings_DeviceManager_DataConnectionsSettings",
    //   linkto: "Settings_DeviceManager_DataConnectionsSettingsList"
    // }
  ]
}

export const Settings_DeviceManager_BluetoothSettingsList = {
  name: "Settings_DeviceManager_BluetoothSettings",
  entries: [
    { label: "Settings_DeviceManager_BluetoothSettings_BT", action: EEntryActions.CHECKBOX, selected: true },
    { label: "Settings_DeviceManager_BluetoothSettings_BTName", action: EEntryActions.NONE }
  ]
}

export const Settings_DeviceManager_WiFiSettingsList = {
  name: "Settings_DeviceManager_WiFiSettings",
  entries: [
    { label: "Settings_DeviceManager_WiFiSettings_WiFi", action: EEntryActions.CHECKBOX },
    { label: "Settings_DeviceManager_WiFiSettings_EnableInternetAccess", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_DeviceManager_WiFiSettings_ConfigureVehicleHotspot",
      action: EEntryActions.NONE
    }
  ]
}

export const Settings_DeviceManager_WiFiSettings_ConfigureVehicleHotspotList = {
  name: "Settings_DeviceManager_WiFiSettings_ConfigureVehicleHotspot",
  entries: [{}]
}

// export const Settings_DeviceManager_DataConnectionsSettingsList = {
//   name: "Settings_DeviceManager_DataConnectionsSettings",
//   entries: [{}]
// }
/*------------------------------AUDIO SETTINGS------------------------------*/

export const settings_AudioList = {
  name: "Settings_Audio",
  entries: [
    { label: "Settings_Main_Volume", linkto: "settings_VolumeList" },
    { label: "Settings_Main_Sound", linkto: "settings_SoundList" }
  ]
}

export const settings_VolumeList = {
  name: "Settings_Volume",
  entries: [
    { label: "Settings_Volume_NavAnnouncements", action: EEntryActions.SLIDER },
    { label: "Settings_Volume_ParkAssistVolume", action: EEntryActions.SLIDER },
    { label: "Settings_Volume_AdjustWhenParkAssistActive", action: EEntryActions.SLIDER },
    { label: "Settings_Volume_AdjustToRoadNoise", action: EEntryActions.SLIDER },
    // { label: "Settings_Volume_Warning", action: EEntryActions.SLIDER },
    { label: "Settings_Volume_LaneDeparture", action: EEntryActions.SLIDER },
    { label: "Settings_Volume_Ringtone", action: EEntryActions.SLIDER },
    { label: "Settings_Volume_MuteNavigationDuringCalls", action: EEntryActions.CHECKBOX, selected: false },
    // { label: "Settings_Volume_Message", action: EEntryActions.CHECKBOX, selected: false },
    // { label: "Settings_Volume_BrandTone", action: EEntryActions.CHECKBOX, selected: false },
    { label: "Settings_Volume_TouchTones", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
    // { label: "Settings_Volume_TouchMiko", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

export const settings_SoundList = {
  name: "Settings_Sound",
  entries: [
    { label: "Settings_Volume_BassAndTreble", action: EEntryActions.SLIDEOUT },
    { label: "Settings_Volume_BalanceAndFader", action: EEntryActions.SLIDEOUT }
  ]
}

/*------------------------------VOICE CONTROL SETTINGS------------------------------*/

export const settings_VoiceControlList = {
  name: "Settings_VoiceControl",
  entries: [
    { label: "Settings_VoiceControl_HeyPorsche", action: EEntryActions.CHECKBOX, selected: true },
    {
      label: "Settings_VoiceControl_ShortDialogs1",
      secondLine: "Settings_VoiceControl_ShortDialogs2",
      action: EEntryActions.CHECKBOX,
      selected: true
    },
    {
      label: "Settings_VoiceControl_InterruptVoiceControl1",
      secondLine: "Settings_VoiceControl_InterruptVoiceControl2",
      action: EEntryActions.CHECKBOX,
      selected: true
    },
    { label: "Settings_VoiceControl_OnlineVoiceRecognition", action: EEntryActions.CHECKBOX, selected: true }
  ]
}

/*------------------------------ CAR-Drive SETTINGS --------------------------------*/

export const settings_CarDrive_Settings = {
  name: "Settings_Options",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    {
      label: "Settings_Car_Drive_IndividualDriveModeConfiguration",
      linkto: "Settings_Car_Drive_IndividualDriveModeConfiguration"
    },
    { label: "Settings_Car_Drive_RangeDriveModeConfiguration", linkto: "settings_RangeDriveMode_Configuration" },
    { label: "Settings_Car_VehicleSettings", linkto: "settings_VehicleList_Settings" }
  ]
}

export const settings_RangeDriveMode_Configuration = {
  name: "Settings_Car_Drive_RangeDriveModeConfiguration",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    {
      label: "Settings_RangeDriveMode_Configuration_Air_Conditioning",
      disabled: true,
      linkto: "Settings_Car_Drive_RangeDriveModeConfiguration"
    },
    {
      label: "Settings_RangeDriveMode_Configuration_Maximum_Speed",
      disabled: true,
      linkto: "Settings_Car_Drive_RangeDriveModeConfiguration"
    },
    {
      label: "Settings_RangeDriveMode_Configuration_PredictiveConsumption_Title",
      secondLine: "Settings_RangeDriveMode_Configuration_PredictiveConsumption_Description",
      action: EEntryActions.CHECKBOX,
      disabled: true
    }
  ]
}

export const Settings_Car_Drive_IndividualDriveModeConfiguration = {
  name: "Settings_Car_Drive_IndividualDriveModeConfiguration",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    {
      label: "dummy",
      linkto: ""
    }
  ]
}

/*------------------------------ CAR-Assist SETTINGS --------------------------------*/
export const settings_CarAssist_Settings = {
  name: "Settings_Options",
  decoratorClass: "content__car-assist--rhd-nochrono",
  entries: [
    {
      label: "Settings_Car_Assist_AssistanceSystemSettings",
      linkto: "settings_AssistanceSystemsList"
    },
    { label: "Settings_Car_Assist_CarSettings", linkto: "settings_VehicleList_Settings" }
  ]
}

/*------------------------------ CAR-Trip SETTINGS --------------------------------*/
export const settings_CarTrip_Settings = {
  name: "Settings_Options",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    {
      label: "Settings_Car_Trip_ConfigureTripPersonally",
      linkto: "settings_CarTrip_ConfigureTripPersonally"
    },
    { label: "Settings_Car_Trip_ResetTripData", linkto: "settings_CarTrip_ResetTripData" },
    { label: "Settings_Car_Trip_VehicleSettings", linkto: "settings_VehicleList_Settings" }
  ]
}

export const settings_CarTrip_ConfigureTripPersonally = {
  name: "Settings_Car_Trip_ConfigureTripPersonally",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    { label: "Settings_Car_Trip_ConfigureTripPersonally_line1", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Car_Trip_ConfigureTripPersonally_line2", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Car_Trip_ConfigureTripPersonally_line3", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Car_Trip_ConfigureTripPersonally_line4", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

export const settings_CarTrip_ResetTripData = {
  name: "Settings_Car_Trip_ResetTripData",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [
    {
      label: "Settings_Car_Trip_ResetTripData_ResetTripSince",
      linkto: "asdf"
    },
    { label: "Settings_Car_Trip_ResetTripData_ResetTotalTripValues", linkto: "asdf" },
    { label: "Settings_Car_Trip_ResetTripData_ResetMaxTripValues", linkto: "asdf" },
    { label: "Settings_Car_Trip_ResetTripData_ResetAllTripData", linkto: "adsf" }
  ]
}

/*------------------------------ CAR-Comfort SETTINGS --------------------------------*/
export const settings_CarComfort_Settings = {
  name: "Settings_Options",
  decoratorClass: "content__car-drive--rhd-nochrono",
  entries: [{ label: "Settings_Car_Trip_VehicleSettings", linkto: "settings_VehicleList_Settings" }]
}
/*------------------------------VEHICLE SETTINGS------------------------------*/

export const settings_VehicleList = {
  name: "Settings_Options",
  detailScreenVideo: require("@pag/center/assets/mp4/car-drive-menu/vehicle.mp4"),
  entries: [
    {
      label: "Settings_Vehicle_ManualCleaningPositionSpoiler",
      action: EEntryActions.SWITCH
    },
    { label: "Settings_Vehicle_ParkingBrake", action: EEntryActions.SWITCH },
    { label: "Settings_Vehicle_ShortcutButtons", linkto: "Settings_Vehicle_ShortcutButtonsList" },
    { label: "Settings_Vehicle_TrunkLidAndTailgates", linkto: "Settings_Vehicle_TrunkLidAndTailgatesList" },
    { label: "Settings_Vehicle_VehicleLockingSystems", linkto: "Settings_Vehicle_VehicleLockingSystemsList" },
    { label: "Settings_Vehicle_TyrePressureMonitoring", linkto: "Settings_Vehicle_TyrePressureMonitoringList" },
    { label: "Settings_Vehicle_LightAndVisibility", linkto: "Settings_Vehicle_LightAndVisibilityList" },
    { label: "Settings_Vehicle_AdditionalChassisSettings", linkto: "Settings_Vehicle_AdditionalChassisSettingsList" }
    // { label: "Settings_Vehicle_ParkingBrake_PredictiveChassis", action: EEntryActions.NONE }
  ]
}

export const settings_VehicleList_Settings = {
  ...settings_VehicleList,
  name: "Settings_Vehicle"
}

export const Settings_Vehicle_ShortcutButtonsList = {
  name: "Settings_Vehicle_ShortcutButtons",
  decoratorClass: "content__jokerkey-main--lhd-nochrono",
  entries: [
    { label: "Settings_Vehicle_ShortcutButtons_SteeringWheel", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Vehicle_ShortcutButtons_ClusterButton", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

export const Settings_Vehicle_TrunkLidAndTailgatesList = {
  name: "Settings_Vehicle_TrunkLidAndTailgates",
  decoratorClass: "content__flaps--mechanical",
  entries: [
    { label: "Settings_Vehicle_TrunkLidAndTailgates_OpenBonnet", action: EEntryActions.NONE, icon: "" },
    { label: "Settings_Vehicle_TrunkLidAndTailgates_OpenBoot", action: EEntryActions.NONE, icon: "" },
    { label: "Settings_Vehicle_TrunkLidAndTailgate_CloseBoot", action: EEntryActions.NONE, icon: "" }
  ]
}

export const Settings_Vehicle_VehicleLockingSystemsList = {
  name: "Settings_Vehicle_VehicleLockingSystems",
  detailScreenVideo: require("@pag/center/assets/mp4/car-drive-menu/vehicle-lock.mp4"),
  entries: [
    { label: "Settings_Vehicle_VehicleLockingSystems_DoorUnlocking", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    {
      label: "Settings_Vehicle_VehicleLockingSystems_ComfortAccess",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_ComfortAccessLine2",
      action: EEntryActions.CHECKBOX
    },
    { label: "Settings_Vehicle_VehicleLockingSystems_FoldInMirrors", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_VehicleLockingSystems_LockWhileDriving", action: EEntryActions.CHECKBOX },
    {
      label: "Settings_Vehicle_VehicleLockingSystems_TailgateComfortAccess",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_TailgateComfortAccessLine2",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_Vehicle_VehicleLockingSystems_AutoUnlock",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_AutoUnlockLine2",
      action: EEntryActions.CHECKBOX
    },

    {
      label: "Settings_Vehicle_VehicleLockingSystems_UnlockVicinity",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_UnlockVicinityLine2",
      action: EEntryActions.CHECKBOX
    },
    {
      label: "Settings_Vehicle_VehicleLockingSystems_InteriorSurveillance",
      secondLine: "Settings_Vehicle_VehicleLockingSystems_InteriorSurveillanceLine2",
      action: EEntryActions.CHECKBOX
    },

    { label: "Settings_Vehicle_VehicleLockingSystems_AntiTheft", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_Vehicle_TyrePressureMonitoringList = {
  name: "Settings_Vehicle_TyrePressureMonitoring",
  decoratorClass: "content__tyre--pressure",
  entries: [
    { label: "Settings_Vehicle_TyrePressureMonitoring_PressureDeviation", action: EEntryActions.NONE },
    { label: "Settings_Vehicle_TyrePressureMonitoring_Selection", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_Vehicle_TyrePressureMonitoring_FullLoad", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_TyrePressureMonitoring_ComfortPressure", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_Vehicle_LightAndVisibilityList = {
  name: "Settings_Vehicle_LightAndVisibility",
  detailScreenVideo: require("@pag/center/assets/mp4/car-drive-menu/vehicle-lights.mp4"),
  entries: [
    {
      label: "Settings_Vehicle_LightAndVisibility_Interior",
      linkto: "Settings_Vehicle_LightAndVisibility_InteriorList"
    },
    {
      label: "Settings_Vehicle_LightAndVisibility_Exterior",
      linkto: "Settings_Vehicle_LightAndVisibility_ExteriorList"
    },
    {
      label: "Settings_Vehicle_LightAndVisibility_Reversing",
      linkto: "Settings_Vehicle_LightAndVisibility_ReversingList"
    },
    { label: "Settings_Vehicle_LightAndVisibility_WiperWithRainSensor", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_Vehicle_LightAndVisibility_InteriorList = {
  name: "Settings_Vehicle_LightAndVisibility_Interior",
  decoratorClass: "content__interiorlight--lhd-nochrono",
  entries: [
    { label: "Settings_Vehicle_LightAndVisibility_Interior_AfterGlow", action: EEntryActions.SLIDER },
    { label: "Settings_Vehicle_LightAndVisibility_Interior_Brightness", action: EEntryActions.SLIDER },
    { label: "Settings_Vehicle_LightAndVisibility_Interior_InteriorLightingLock", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_Vehicle_LightAndVisibility_ExteriorList = {
  name: "Settings_Vehicle_LightAndVisibility_Exterior",
  entries: [
    { label: "Settings_Vehicle_LightAndVisibility_Exterior_AfterGlow", action: EEntryActions.SLIDER },
    { label: "Settings_Vehicle_LightAndVisibility_Exterior_DippedBeam", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_LightAndVisibility_Exterior_BeamAdaption", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_LightAndVisibility_Exterior_DynamicHighBeam", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_Vehicle_LightAndVisibility_ReversingList = {
  name: "Settings_Vehicle_LightAndVisibility_Reversing",
  detailScreenVideo: require("@pag/center/assets/mp4/car-drive-menu/vehicle-lights-reversing.mp4"),
  entries: [
    { label: "Settings_Vehicle_LightAndVisibility_Reversing_WipeWhenReversing", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_LightAndVisibility_Reversing_LowerMirror", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_Vehicle_AdditionalChassisSettingsList = {
  name: "Settings_Vehicle_AdditionalChassisSettings",
  detailScreenVideo: require("@pag/center/assets/mp4/car-drive-menu/vehicle-chassis.mp4"),
  entries: [
    { label: "Settings_Vehicle_AdditionalChassisSettings_RearAxle", action: EEntryActions.CHECKBOX },
    { label: "Settings_Vehicle_AdditionalChassisSettings_ChassisAdaption", action: EEntryActions.CHECKBOX }
  ]
}

/*------------------------------ASSISTANCE SYSTEM SETTINGS------------------------------*/

export const settings_AssistanceSystemsList = {
  name: "Settings_AssistanceSystems",
  entries: [
    { label: "Settings_AssistanceSystems_ParkAssist", linkto: "Settings_AssistanceSystems_ParkAssistList" },
    {
      label: "Settings_AssistanceSystems_WarnAndBrakeAssist",
      linkto: "Settings_AssistanceSystems_WarnAndBrakeAssistList"
    },
    { label: "Settings_AssistanceSystems_PorscheInnoDrive", linkto: "Settings_AssistanceSystems_PorscheInnoDriveList" },
    {
      label: "Settings_AssistanceSystems_AdaptiveSpeedLimiter",
      linkto: "Settings_AssistanceSystems_AdaptiveSpeedLimiterList"
    },
    {
      label: "Settings_AssistanceSystems_LaneDepartureWarning",
      linkto: "Settings_AssistanceSystems_LaneDepartureWarningList"
    },
    {
      label: "Settings_AssistanceSystems_LaneChangeAssist",
      linkto: "Settings_AssistanceSystems_LaneChangeAssistList"
    },
    {
      label: "Settings_AssistanceSystems_NightViewAssist",
      linkto: "Settings_AssistanceSystems_NightViewAssistList"
    },
    {
      label: "Settings_AssistanceSystems_TrafficSignRecognition",
      linkto: "Settings_AssistanceSystems_TrafficSignRecognitionList"
    },
    {
      label: "Settings_AssistanceSystems_PersonalSpeedLimits",
      linkto: "Settings_Display_InstrumentCluster_PersonalSpeedLimitsList"
    },
    {
      label: "Settings_AssistanceSystems_HazardousConditions",
      linkto: "Settings_AssistanceSystems_HazardousConditionsList"
    },
    { label: "Settings_AssistanceSystems_ExteriorLights", linkto: "Settings_AssistanceSystems_ExteriorLightsList" }
    // { label: "Settings_AssistanceSystems_DataExport", action: EEntryActions.NONE }
  ]
}

export const Settings_AssistanceSystems_ExteriorLightsList = {
  name: "Settings_AssistanceSystems_ExteriorLights",
  entries: [
    { label: "Settings_AssistanceSystems_ExteriorLights_BeamAdaption", action: EEntryActions.CHECKBOX },
    { label: "Settings_AssistanceSystems_ExteriorLights_DynamicHighBeam", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_AssistanceSystems_TrafficSignRecognitionList = {
  name: "Settings_AssistanceSystems_TrafficSignRecognition",
  entries: [
    { label: "Settings_AssistanceSystems_TrafficSignRecognition_Cluster", action: EEntryActions.CHECKBOX }
    // {
    //   label: "Settings_AssistanceSystems_TrafficSignRecognition_Exceed",
    //   secondLine: "Settings_AssistanceSystems_TrafficSignRecognition_Exceed_Secondline",
    //   action: EEntryActions.SLIDER
    // }
  ]
}

export const Settings_AssistanceSystems_HazardousConditionsList = {
  name: "Settings_AssistanceSystems_HazardousConditions",
  entries: [
    { label: "Settings_AssistanceSystems_HazardousConditions_Cluster", action: EEntryActions.CHECKBOX }
    // { label: "Settings_AssistanceSystems_HazardousConditions_HeadUp", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_AssistanceSystems_NightViewAssistList = {
  name: "Settings_AssistanceSystems_NightViewAssist",
  entries: [
    { label: "Settings_AssistanceSystems_NightViewAssist_Contrast", action: EEntryActions.SLIDER },
    { label: "Settings_AssistanceSystems_NightViewAssist_WarningTime", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

export const Settings_AssistanceSystems_AdaptiveSpeedLimiterList = {
  name: "Settings_AssistanceSystems_AdaptiveSpeedLimiter",
  entries: [
    { label: "Settings_AssistanceSystems_AdaptiveSpeedLimiter_ConsiderSpeedLimits", action: EEntryActions.CHECKBOX },
    { label: "Settings_AssistanceSystems_AdaptiveSpeedLimiter_MaximumSpeed", action: EEntryActions.SLIDER },
    {
      label: "Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    }
  ]
}

export const Settings_AssistanceSystems_PorscheInnoDriveList = {
  name: "Settings_AssistanceSystems_PorscheInnoDrive",
  entries: [
    { label: "Settings_AssistanceSystems_PorscheInnoDrive_MaxSpeed", action: EEntryActions.SLIDER },
    { label: "Settings_AssistanceSystems_PorscheInnoDrive_ConsiderSpeedLimits", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_AssistanceSystems_ParkAssistList = {
  name: "Settings_AssistanceSystems_ParkAssist_Headline",
  entries: [
    { label: "Settings_AssistanceSystems_ParkAssist_Volume", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_AssistanceSystems_ParkAssist_VolumeReduction", action: EEntryActions.SLIDER },
    { label: "Settings_AssistanceSystems_ParkAssist_AutoActivate", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_AssistanceSystems_WarnAndBrakeAssistList = {
  name: "Settings_AssistanceSystems_WarnAndBrakeAssist",
  entries: [
    {
      label: "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning",
      secondLine: "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning_SecondLine",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    },
    {
      label: "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning",
      secondLine: "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning_SecondLine",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    },
    { label: "Settings_AssistanceSystems_WarnAndBrakeAssist_TurnAssist", action: EEntryActions.CHECKBOX },
    { label: "Settings_AssistanceSystems_WarnAndBrakeAssist_SwerveAssist", action: EEntryActions.CHECKBOX }
  ]
}

export const Settings_AssistanceSystems_LaneDepartureWarningList = {
  name: "Settings_AssistanceSystems_LaneDepartureWarning",
  entries: [
    { label: "Settings_AssistanceSystems_LaneDepartureWarning_Volume", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

export const Settings_AssistanceSystems_LaneChangeAssistList = {
  name: "Settings_AssistanceSystems_LaneChangeAssist",
  entries: [
    { label: "Settings_AssistanceSystems_LaneChangeAssist_Brightness", action: EEntryActions.RADIOBUTTON_SLIDEOUT }
  ]
}

/*------------------------------NOTIFICATION CENTER SETTINGS------------------------------*/

export const settings_NotificationCenterList = {
  name: "Settings_NotificationCenter",
  entries: [
    { label: "Settings_NotificationCenter_Allow", action: EEntryActions.SLIDEOUT },
    { label: "Settings_NotificationCenter_Sound", action: EEntryActions.CHECKBOX, selected: true }
  ]
}

/*------------------------------RADIO SETTINGS------------------------------*/

export const settings_RadioList = {
  name: "Settings_Radio",
  entries: [
    { label: "Settings_Radio_TrafficNotices", action: EEntryActions.CHECKBOX, selected: true },
    { label: "Settings_Radio_StationTracking", action: EEntryActions.CHECKBOX, selected: false },
    { label: "Settings_Radio_OnlineStationTracking", action: EEntryActions.CHECKBOX, selected: false },
    { label: "Settings_Radio_HDRadio", action: EEntryActions.CHECKBOX, selected: true }
    // { label: "Settings_Radio_Programmtype", action: EEntryActions.CHECKBOX, selected: true }
  ]
}

/*------------------------------NAVIGATION SETTINGS------------------------------*/

export const settings_NavigationList = {
  name: "Settings_Options",
  icon: "icon-north_arrow",
  entries: [
    { label: "Settings_Navigation_RouteOptions", linkto: "Settings_Navigation_RouteOptionsList" }, //
    { label: "Settings_Navigation_AlternativeRoutes", action: EEntryActions.LINK, arrow: true }, //linkto: "Settings_Navigation_AlternativeRoutesList"
    { label: "Settings_Navigation_EditRoute", action: EEntryActions.LINK, arrow: true }, //linkto: "Settings_Navigation_EditRouteList"
    {
      label: "Settings_Navigation_SaveDestination",
      action: EEntryActions.POPUP,
      popupText: "Settings_Navigation_SaveDestination_PopupText"
    },
    // { label: "Settings_Navigation_TrafficInformationPopup", action: EEntryActions.CHECKBOX, selected: true },
    { label: "Settings_Navigation_NavigationSettings", linkto: "Settings_Navigation_NavigationSettingsList" }
  ]
}

export const settings_NavigationList_Settings = {
  name: "Settings_Main_Navigation_Settings",
  icon: "icon-north_arrow",
  entries: [...settings_NavigationList.entries]
}

export const Settings_Navigation_RouteOptionsList = {
  name: "Settings_Navigation_RouteOptions",
  icon: "icon-north_arrow",
  entries: [
    { label: "Settings_Navigation_RouteOptions_Avoid", action: EEntryActions.SLIDEOUT },
    // {
    //   label: "Settings_Navigation_RouteOptions_ShowRoutesWhenStartingRoutesGuidance",
    //   action: EEntryActions.CHECKBOX,
    //   selected: false
    // },
    {
      label: "Settings_Navigation_RouteOptions_DisplayRouteSelection",
      action: EEntryActions.CHECKBOX,
      selected: false
    },
    {
      label: "Settings_Navigation_RouteOptions_PorscheChargingPlaner",
      action: EEntryActions.CHECKBOX,
      selected: true
    }
    // {
    //   label: "Settings_Navigation_RouteOptions_PorscheChargingService",
    //   // secondLine: "Settings_Navigation_RouteOptions_PorscheChargingService_SecondLine",
    //   action: EEntryActions.CHECKBOX,
    //   selected: false
    // },
    // {
    //   label: "Settings_Navigation_RouteOptions_RecommendedBatteryChargeAtDestination",
    //   action: EEntryActions.CHECKBOX,
    //   selected: true
    // },
    // {
    //   label: "Settings_Navigation_RouteOptions_ManualBatteryChargeAtDestination",
    //   action: EEntryActions.SLIDER
    // }
  ]
}

export const Settings_Navigation_AlternativeRoutesList = {
  name: "Settings_Navigation_AlternativeRoutes",
  icon: "icon-north_arrow",
  entries: []
}

export const Settings_Navigation_EditRouteList = {
  name: "Settings_Navigation_EditRoute",
  icon: "icon-north_arrow",
  entries: []
}

export const Settings_Navigation_NavigationSettingsList = {
  name: "Settings_Navigation_NavigationSettings",
  icon: "icon-north_arrow",
  entries: [
    {
      label: "Settings_Navigation_NavigationSettings_MapSettings",
      linkto: "Settings_Navigation_NavigationSettings_MapSettingsList"
    },
    // {
    //   label: "Settings_Navigation_NavigationSettings_TrafficInformation",
    //   linkto: "Settings_Navigation_NavigationSettings_TrafficInformationList"
    // },
    // { label: "Settings_Navigation_NavigationSettings_SmartRouting", action: EEntryActions.CHECKBOX, selected: false },
    // {
    //   label: "Settings_Navigation_NavigationSettings_SmartRoutingDeleteHistory",
    //   action: EEntryActions.POPUP,
    //   popupText: "Settings_Navigation_NavigationSettings_SmartRoutingDeleteHistory_PopupText"
    // },
    // { label: "Settings_Navigation_NavigationSettings_RangeWarning", action: EEntryActions.CHECKBOX, selected: false },
    {
      label: "Settings_Navigation_NavigationSettings_DisplayCountryInformation",
      action: EEntryActions.CHECKBOX,
      selected: true
    },
    { label: "Settings_Navigation_NavigationSettings_NavigationAnnouncements", action: EEntryActions.SLIDER },
    // {
    //   label: "Settings_Navigation_NavigationSettings_VoiceGuidanceMode",
    //   linkto: "Settings_Navigation_NavigationSettings_VoiceGuidanceModeList"
    // },
    {
      label: "Settings_Navigation_NavigationSettings_DeleteRecentDestinationsAndFavorites",
      action: EEntryActions.NONE,
      disabled: true
    },
    { label: "Settings_Navigation_NavigationSettings_DemoMode", action: EEntryActions.CHECKBOX, selected: false },
    {
      label: "Settings_Navigation_NavigationSettings_SetLastDestinationAsStartingPoint",
      action: EEntryActions.NONE,
      disabled: true
    }
  ]
}

export const Settings_Navigation_NavigationSettings_MapSettingsList = {
  name: "Settings_Navigation_NavigationSettings_MapSettings",
  icon: "icon-north_arrow",
  entries: [
    {
      label: "Settings_Navigation_NavigationSettings_MapSettings_AutoZoom",
      action: EEntryActions.CHECKBOX,
      selected: false
    },
    {
      label: "Settings_Navigation_NavigationSettings_MapSettings_DisplayPois",
      action: EEntryActions.CHECKBOX,
      selected: false
    },
    {
      label: "Settings_Navigation_NavigationSettings_MapSettings_3DBuilding",
      action: EEntryActions.CHECKBOX,
      selected: false
    },
    {
      label: "Settings_Navigation_NavigationSettings_MapSettings_ShowSpeedLimits",
      action: EEntryActions.CHECKBOX,
      selected: false
    },
    {
      label: "Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    }
  ]
}

export const Settings_Navigation_NavigationSettings_TrafficInformationList = {
  name: "Settings_Navigation_NavigationSettings_TrafficInformation",
  icon: "icon-north_arrow",
  entries: [
    {
      label: "Settings_Navigation_NavigationSettings_TrafficInformation_Merging",
      action: EEntryActions.CHECKBOX,
      selected: true
    },
    {
      label: "Settings_Navigation_NavigationSettings_TrafficInformation_RailroadCrossing",
      action: EEntryActions.CHECKBOX,
      selected: true
    }
  ]
}

export const Settings_Navigation_NavigationSettings_VoiceGuidanceModeList = {
  name: "Settings_Navigation_NavigationSettings_VoiceGuidanceMode",
  icon: "icon-north_arrow",
  entries: [
    {
      label: "Settings_Navigation_NavigationSettings_VoiceGuidanceMode_InDetail",
      action: EEntryActions.RADIOBUTTON,
      selected: false
    },
    {
      label: "Settings_Navigation_NavigationSettings_VoiceGuidanceMode_Simple",
      action: EEntryActions.RADIOBUTTON,
      selected: true
    },
    {
      label: "Settings_Navigation_NavigationSettings_VoiceGuidanceMode_Off",
      action: EEntryActions.RADIOBUTTON,
      selected: false
    }
  ]
}

/*------------------------------MEDIA SETTINGS------------------------------*/

export const settings_MediaList = {
  name: "Settings_Options",
  entries: [
    { label: "Settings_Media_AdjustSortingOfSources", linkto: "Settings_Media_AdjustSortingOfSourcesList" },
    { label: "Settings_Radio", linkto: "settings_RadioList" },
    { label: "Settings_Main_DeviceManager", linkto: "settings_DeviceManagerList" },
    { label: "Settings_Main_PrivacyAndPorscheConnect", linkto: "settings_PrivacyAndPorscheConnectList" }
  ]
}

export const Settings_Media_AdjustSortingOfSourcesList = {
  name: "Settings_Media_AdjustSortingOfSources",
  renderDetails: (cb: any) => (
    <InfoMessage
      useFullScreenWrapper={false}
      developerText="Settings_Media_AdjustSortingOfSources_Detail_InfoMessage_LabelText_DragAroundToRearrange"
      icon={IconType.icon_information_outline}
    >
      <Button
        labelText="Settings_Media_AdjustSortingOfSources_Detail_InfoMessage_ButtonText_Confirm"
        onClick={() => cb()}
      />
    </InfoMessage>
  ),
  entries: [
    {
      label: "Settings_Media_AdjustSortingOfSources_Bluetooth",
      action: EEntryActions.MOVE,
      icon: "icon-source_bluetooth",
      end: "icon-drag_vertical"
    },
    {
      label: "Settings_Media_AdjustSortingOfSources_Radio",
      action: EEntryActions.MOVE,
      icon: "icon-radio",
      end: "icon-drag_vertical"
    },
    {
      label: "Settings_Media_AdjustSortingOfSources_USB",
      action: EEntryActions.MOVE,
      icon: "icon-usb",
      end: "icon-drag_vertical"
    }
  ]
}

/*------------------------------PHONE TILE SETTINGS------------------------------*/

export const settings_PhoneTileList = {
  name: "Settings_Options",
  icon: "icon-phone_receiver",
  entries: [
    { label: "Settings_Main_DeviceManager", action: "settings_DeviceManagerList" },
    { label: "Settings_PhoneContext_PhoneSettings", linkto: "settings_PhoneList", hideIcon: true }
  ]
}

/*------------------------------NEWS TILE SETTINGS------------------------------*/

export const settings_NewsTileList = {
  name: "Settings_Options",
  entries: [
    {
      label: "Settings_Feedly_Abmelden",
      action: EEntryActions.NONE
    }
  ]
}

/*------------------------------CALENDER TILE SETTINGS------------------------------*/

export const settings_CalenderTileList = {
  name: "Settings_Options",
  entries: [
    {
      label: "Calender_Settings_Disconnect",
      action: EEntryActions.NONE
    },
    {
      label: "Calender_Settings_PhoneCalendar",
      action: EEntryActions.NONE
    },
    {
      label: "Calender_Settings_PorscheCalendar",
      action: EEntryActions.NONE
    },
    {
      label: "Calender_Settings_StartWeek",
      action: EEntryActions.RADIOBUTTON_SLIDEOUT
    },
    {
      label: "Calender_Settings_AddKalendaer",
      linkto: "Calender_Settings_AddKalendaerList"
    },
    {
      label: "Calender_Settings_PorscheConnect",
      linkto: "settings_PrivacyAndPorscheConnectList"
    },
    {
      label: "Calender_Settings_Imprint",
      action: EEntryActions.NONE
    }
  ]
}

export const Calender_Settings_AddKalendaerList = {
  name: "Calender_Settings_AddKalendaerList_Headline",
  entries: [
    {
      label: "Calender_Settings_AddKalendaerList_Google",
      action: EEntryActions.NONE,
      disabled: true
    },
    {
      label: "Calender_Settings_AddKalendaerList_Office365",
      action: EEntryActions.NONE,
      disabled: true
    },
    {
      label: "Calender_Settings_AddKalendaerList_Exchange",
      action: EEntryActions.LINK,
      link: ROUTES_MAIN.ADD_CALENDAR
    }
  ]
}

/*------------------------------WEATHER TILE SETTINGS------------------------------*/

export const settings_WeatherTileList = {
  name: "Settings_Options",
  entries: []
}

/*------------------------------CHARGING SETTINGS------------------------------*/

export const settings_ChargingList = {
  name: "Settings_Options",
  entries: [
    {
      label: "Settings_Charging_ChargingPlug-FirstLine",
      secondLine: "Settings_Charging_ChargingPlug-SecondLine",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_Charging_ActivatePlugAndCharge-FirstLine",
      secondLine: "Settings_Charging_ActivatePlugAndCharge-SecondLine",
      action: EEntryActions.CHECKBOX,
      selected: true
    }
  ]
}

/*------------------------------DEVICES SETTINGS------------------------------*/

export const settings_DevicesList = {
  name: "Settings_Options",
  entries: [
    { label: "Settings_DeviceManager_TelephoneSettings", linkto: "Settings_Phone_GeneralSettingsList" },
    { label: "Settings_DeviceManager_BluetoothSettings", linkto: "Settings_DeviceManager_BluetoothSettingsList" },
    { label: "Settings_DeviceManager_WiFiSettings", linkto: "Settings_DeviceManager_WiFiSettingsList" }
  ]
}

// export const Settings_Devices_ConnectionAssist = {
//   name: "Settings_Main_ConnectionAssist",
//   entries: [
//     {
//       label: "ConnectionAssistant_NewPhone",
//       action: EEntryActions.POPUP,
//       startIcon: IconType.icon_cell_phone,
//       popupText: "Popup_Helptext_functionality-not-available"
//     },
//     {
//       label: "ConnectionAssistant_NewMusicPlayer",
//       action: EEntryActions.POPUP,
//       startIcon: IconType.icon_note,
//       popupText: "Popup_Helptext_functionality-not-available"
//     },
//     {
//       label: "ConnectionAssistant_ConnectAppleCarPlay",
//       action: EEntryActions.POPUP,
//       startIcon: IconType.icon_apple_carplay,
//       popupText: "Popup_Helptext_functionality-not-available"
//     },
//     {
//       label: "ConnectionAssistant_TeachRemoteParkingDevice",
//       action: EEntryActions.POPUP,
//       startIcon: IconType.icon_wlan,
//       popupText: "Popup_Helptext_functionality-not-available"
//     }
//   ]
// }

/*------------------------------UPDATE SETTINGS------------------------------*/

export const settings_UpdatesList = {
  name: "Settings_Options",
  renderDetails: () => (
    <ScrollPanel className="update-details-container__description-area details-screen__text-container">
      <I18nLabel
        className="update-details-container__description"
        text="Settings_Updates_WebApp_Detail_InfoMessage_LabelText_Line1"
      />
      <I18nLabel
        className="update-details-container__description"
        text="Settings_Updates_WebApp_Detail_InfoMessage_LabelText_Line2"
      />
    </ScrollPanel>
  ),
  entries: [
    // {
    //   label: "Settings_Updates_WebApp",
    //   action: EEntryActions.CHECKBOX
    // }
  ]
}

/*------------------------------SPORT CHRONO SETTINGS------------------------------*/

export const Settings_Main_SportChronoList = {
  name: "Settings_SportChrono",
  icon: IconType.icon_timer,
  entries: [
    { label: "Settings_SportChrono_MaxDelta", action: EEntryActions.SLIDER },
    { label: "Settings_SportChrono_Deviation", action: EEntryActions.SLIDER },
    { label: "Settings_SportChrono_ExtendedDataRecording", action: EEntryActions.CHECKBOX, selected: false },
    { label: "Settings_SportChrono_NewLap", action: EEntryActions.CHECKBOX, selected: true },
    { label: "Settings_SportChrono_DisplayPos", action: EEntryActions.CHECKBOX, selected: false }
  ]
}

/*------------------------------PRIVACY AND PORSCHE CONNECT SETTINGS------------------------------*/

export const settings_PrivacyAndPorscheConnectList = {
  name: "Settings_PrivacyAndPorscheConnect",
  entries: [
    { label: "Settings_PrivacyAndPorscheConnect_PrivateMode", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_ServicesWithoutTracking", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_ServiceWithTracking", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_ServicesWithTracking", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    // { label: "Settings_PrivacyAndPorscheConnect_GPS", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_Improvements", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_DataFromPorsche", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_AnonData", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_Divider", divider: true },
    {
      label: "Settings_PrivacyAndPorscheConnect_Overview",
      action: EEntryActions.NONE
      // action: EEntryActions.LINK,
      // link: ROUTES_MAIN.OVERVIEW_PORSCHECONNECTSERVICES
    },
    // {
    //   label: "Settings_PrivacyAndPorscheConnect_ExternalAuthorisations",
    //   linkto: "Settings_PrivacyAndPorscheConnect_ExternalAuthorisationsList"
    // },
    { label: "Settings_PrivacyAndPorscheConnect_ResidualData", action: EEntryActions.NONE },
    { label: "Settings_PrivacyAndPorscheConnect_LegalNotice", action: EEntryActions.NONE },
    { label: "Settings_PrivacyAndPorscheConnect_Privacy", action: EEntryActions.NONE }
  ]
}

export const Settings_PrivacyAndPorscheConnect_ExternalAuthorisationsList = {
  name: "Settings_PrivacyAndPorscheConnect_ExternalAuthorisations",
  entries: [{ label: "Settings_PrivacyAndPorscheConnect_ExternalAuthorisations_NoEntries", action: EEntryActions.NONE }]
}

/*------------------------------WEATHER SETTINGS------------------------------*/

export const Settings_WeatherList = {
  name: "Settings_Options",
  entries: [
    { label: "Settings_PrivacyAndPorscheConnect_PrivateMode", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_ServicesWithoutTracking", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    // { label: "Settings_PrivacyAndPorscheConnect_GPS", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_ServicesWithTracking", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_Improvements", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_DataFromPorsche", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_AnonData", action: EEntryActions.RADIOBUTTON_SLIDEOUT },
    { label: "Settings_PrivacyAndPorscheConnect_Divider", divider: true },
    {
      label: "Settings_PrivacyAndPorscheConnect_Overview",
      action: EEntryActions.NONE
      // action: EEntryActions.LINK,
      // link: ROUTES_MAIN.OVERVIEW_PORSCHECONNECTSERVICES
    },
    {
      label: "Settings_PrivacyAndPorscheConnect_ExternalAuthorisations",
      linkto: "Settings_PrivacyAndPorscheConnect_ExternalAuthorisationsList"
    },
    { label: "Settings_PrivacyAndPorscheConnect_ResidualData", action: EEntryActions.NONE },
    { label: "Settings_PrivacyAndPorscheConnect_LegalNotice", action: EEntryActions.NONE },
    { label: "Settings_PrivacyAndPorscheConnect_Privacy", action: EEntryActions.NONE }
  ]
}

/*------------------------------ACCOUNT SETTINGS------------------------------*/

export const settings_AccountList = {
  name: "Settings_Accounts",
  entries: [
    {
      label: "Settings_Accounts_Guest",
      action: EEntryActions.NONE,
      icon: "icon-person"
    },
    {
      label: "account1",
      name: "Maria Bianchi",
      secondLine: "Settings_Accounts_SecondaryUser",
      action: EEntryActions.MORE_BUTTON,
      icon: "icon-person",
      end: "icon-options_more"
    },
    {
      label: "account2",
      name: "John Doe",
      secondLine: "Settings_Accounts_SecondaryUser",
      action: EEntryActions.MORE_BUTTON,
      icon: "icon-person",
      end: "icon-options_more"
    },
    {
      label: "account3",
      name: "John Smith",
      secondLine: "Settings_Accounts_PrimaryUser",
      action: EEntryActions.MORE_BUTTON,
      icon: "icon-person",
      end: "icon-options_more"
    },
    {
      label: "Settings_Accounts_AddNewAcc",
      action: EEntryActions.LINK,
      icon: "icon-plus",
      link: ROUTES_MAIN.NEW_ACCOUNT
    }
  ]
}

/*------------------------------SOFTWARE INFO SETTINGS------------------------------*/

export const settings_SoftwareInformationList = {
  name: "Settings_SoftwareInfo",
  entries: [
    { label: "Settings_SoftwareInfo_VersionInfo", linkto: "settings_SoftwareInformation_VersionInfoList" },
    { label: "Settings_SoftwareComponents", action: EEntryActions.NONE }
  ]
}

export const settings_SoftwareInformation_VersionInfoList = {
  name: "Settings_SoftwareInfo",
  entries: [
    {
      label: "Settings_SoftwareInformation_UserInterface",
      secondLine: "release-32.9-1934-SNAPSHOT-05.11.2019-13:44:53",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_SoftwareVersion",
      secondLine: "MPR3_AS_POG46_E3267",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_SoftwareVersionPCM",
      secondLine: "X267",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_Hardware",
      secondLine: "H9J1035878B",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_TextVersion",
      secondLine: "MIB3_GUI_32.30_20191025",
      action: EEntryActions.NONE
    },
    // {
    //   label: "Navigation Database",
    //   secondLine: "---",
    //   action: EEntryActions.NONE
    // },
    {
      label: "Settings_SoftwareInformation_NavigationPacket",
      secondLine: "V03959807FD_P0350_EU_2020.05",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_GraceNote",
      secondLine: "Region=EUROPE - Version=6",
      action: EEntryActions.NONE
    },
    {
      label: "Settings_SoftwareInformation_PhoneDriver",
      secondLine: "---",
      action: EEntryActions.NONE
    }
  ]
}

/*------------------------------DEVICES SETTINGS------------------------------*/

export const settings_NotificationList = {
  name: "Settings_Options",
  entries: [{ label: "Settings_Notification_NotificationCenter", linkto: "Settings_Notification_NotificationCenter" }]
}

export const Settings_Notification_NotificationCenter = {
  name: "Settings_Notification_NotificationCenter_Settings",
  entries: [
    { label: "Settings_NotificationCenter_Allow", action: EEntryActions.SLIDEOUT },
    { label: "Settings_NotificationCenter_Sound", action: EEntryActions.CHECKBOX, selected: true }
  ]
}

export const Settings_Notification_NotificationCenter_ReceiveVehicleNotifcationList = {
  name: "Settings_Notification_NotificationCenter_ReceiveVehicleNotifcations",
  entries: [
    // {
    //   label: "Settings_Notification_NotificationCenter_ReceiveVehicleNotifcation_ShowAll",
    //   action: EEntryActions.CHECKBOX,
    //   selected: true
    // },
    {
      label: "Settings_Notification_NotificationCenter_ReceiveVehicleNotifcation_ServiceInformation",
      action: EEntryActions.CHECKBOX,
      selected: true
    },
    {
      label: "Settings_Notification_NotificationCenter_ReceiveVehicleNotifcation_Updates",
      action: EEntryActions.CHECKBOX,
      selected: true
    }
  ]
}

// export const Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotificationsList = {
//   name: "Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotifications",
//   entries: [
//     // {
//     //   label: "Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotifications_ShowAll",
//     //   action: EEntryActions.CHECKBOX,
//     //   selected: true
//     // },
//     {
//       label: "Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotifications_MissedCalls",
//       action: EEntryActions.CHECKBOX,
//       selected: true
//     },
//     {
//       label: "Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotifications_Emails",
//       action: EEntryActions.CHECKBOX,
//       selected: true
//     },
//     {
//       label: "Settings_Notification_NotificationCenter_ReceiveSmartPhoneNotifications_Messages",
//       action: EEntryActions.CHECKBOX,
//       selected: true
//     }
//   ]
// }

// export const Settings_Notification_NotificationCenter_ReceiveInformationNotificationsList = {
//   name: "Settings_Notification_NotificationCenter_ReceiveInformationNotifications",
//   entries: [
//     // {
//     //   label: "Settings_Notification_NotificationCenter_ReceiveInformationNotifications_ShowAll",
//     //   action: EEntryActions.CHECKBOX,
//     //   selected: true
//     // },
//     {
//       label: "Settings_Notification_NotificationCenter_ReceiveInformationNotifications_VoiceControl",
//       action: EEntryActions.CHECKBOX,
//       selected: true
//     },
//     {
//       label: "Settings_Notification_NotificationCenter_ReceiveInformationNotifications_DataConnection",
//       action: EEntryActions.CHECKBOX,
//       selected: true
//     }
//   ]
// }

/*------------------------------DEVICES SETTINGS------------------------------*/

export const settings_HomeLinkList = {
  name: "Settings_Options",
  icon: IconType.icon_homelink,
  entries: [
    { label: "Settings_HomeLink_ConfigureProfileInStandardMode", action: EEntryActions.NONE },
    { label: "Settings_HomeLink_ConfigureProfileInRepairKitMode", action: EEntryActions.NONE },
    { label: "Settings_HomeLink_DeleteAllProfile", action: EEntryActions.NONE }
  ]
}

export const settings_HomeLinkList_Settings = {
  name: "Settings_Main_HomeLink",
  icon: IconType.icon_homelink,
  entries: [...settings_HomeLinkList.entries]
}

/*----------------------------- Launcher Config Settings ------------------ */

export const Settings_Launcher = {
  name: "Settings_Options",
  entries: [
    {
      label: "Settings_Launcher_Context_Options_Adapt_Sorting",
      action: EEntryActions.LINK,
      link: ROUTES_MAIN.LAUNCHER_CONFIG
    }
  ]
}
