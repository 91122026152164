import { SET_ACTIVE_NOTIFICATION, CLOSE_QUICK_ACTION_OF_ACTIVE_NOTIFICATION, ActiveNotificationType } from "./types"

const initialState = {}

export function activeNotificationReducer(state = initialState, action: ActiveNotificationType) {
  switch (action.type) {
    case SET_ACTIVE_NOTIFICATION:
      return action.notification
    case CLOSE_QUICK_ACTION_OF_ACTIVE_NOTIFICATION: {
      //@ts-ignore
      if (state && state.clicked) {
        return {
          ...state,
          clicked: false
        }
      }
      return state
    }
    default:
      return state
  }
}
