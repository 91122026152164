export const UPDATE_LAUNCHER_TILE_ORDER = "UPDATE_LAUNCHER_TILE_ORDER"

export type LauncherTileState = {
  tiles: LauncherTile[]
}

export type LauncherTile = {
  text: string
  url: string
  iconType: string
}

export type updateLauncherTileOrderAction = {
  type: typeof UPDATE_LAUNCHER_TILE_ORDER
  payload: {
    tiles: LauncherTile[]
  }
}
export type LauncherTileActionType = updateLauncherTileOrderAction
