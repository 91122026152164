import { BalanceAndFaderState, BalanceAndFaderType, SET_BALANCE, SET_FADE } from "./types"

const initialStateBalanceAndFader: BalanceAndFaderState = {
  balance: 0,
  fade: 0
}

export function balanceAndFaderReducer(
  state = initialStateBalanceAndFader,
  action: BalanceAndFaderType
): BalanceAndFaderState {
  switch (action.type) {
    case SET_BALANCE:
      return {
        ...state,
        balance: action.payload
      }
    case SET_FADE:
      return {
        ...state,
        fade: action.payload
      }
    default:
      return state
  }
}
