import {
  settingsState,
  SET_VISIBLE_SETTINGS_LIST,
  SET_CURRENT_RADIO_BUTTON_LIST_ITEM,
  Action,
  UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE
} from "./types"
import * as lists from "@pag/center/views/settingsScreen/listContents/settingsListContents"

const initialState: settingsState = {
  visibleList: lists.settings_MainList,
  currentRadioButtonListItem: "",
  detailsScreenOverlayVisible: false
}

export function settingsReducer(state = initialState, action: Action): settingsState {
  switch (action.type) {
    case SET_VISIBLE_SETTINGS_LIST:
      return { ...state, visibleList: action.visibleList }
    case SET_CURRENT_RADIO_BUTTON_LIST_ITEM:
      return { ...state, currentRadioButtonListItem: action.itemName }
    case UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE:
      return { ...state, detailsScreenOverlayVisible: action.visible }
    default:
      return state
  }
}
