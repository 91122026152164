import { UPDATE_PHONE_SEARCH, UpdatePhoneSearchAction } from "./types"

const phoneSearchInitialState: boolean = false

export function phoneSearchReducer(state = phoneSearchInitialState, action: UpdatePhoneSearchAction) {
  switch (action.type) {
    case UPDATE_PHONE_SEARCH:
      return action.active
    default:
      return state
  }
}
