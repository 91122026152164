import { ECarDriveMainScreenItemNames } from "@pag/center/views/carScreen/enums"
import {
  SetDriveModeAction,
  SET_DRIVE_MODE,
  SetActiveDriveMainScreenListItemAction,
  SET_ACTIVE_DRIVE_MAIN_SCREEN_LIST_ITEM,
  DriveMainScreenListState
} from "./types"

const driveMainScreenListItemInitialState: ECarDriveMainScreenItemNames = ECarDriveMainScreenItemNames.DEFAULT

export function driveMainScreenListItemReducer(
  state = driveMainScreenListItemInitialState,
  action: SetActiveDriveMainScreenListItemAction
): DriveMainScreenListState {
  switch (action.type) {
    case SET_ACTIVE_DRIVE_MAIN_SCREEN_LIST_ITEM:
      if (state === action.item) {
        return ECarDriveMainScreenItemNames.DEFAULT
      }
      return action.item
    default:
      return state
  }
}

const driveModeInitialState: string = "sportPlus"

export function driveModeReducer(state = driveModeInitialState, action: SetDriveModeAction): string {
  switch (action.type) {
    case SET_DRIVE_MODE:
      return action.mode
    default:
      return state
  }
}
