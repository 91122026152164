import React from "react"
import ClassNames from "classnames"

import Icon from "@pag/center/components/icon/Icon"
import { IconType } from "@pag/center/components/icon/iconType"

import "./poiStack.scss"

const MAX_NUMBER_OF_VISIBLE_POI_ICONS = 2 // TODO

interface IPoiStack {
  className?: string
  amount: number
  icons: Set<string>
  showPoiStackList: any
}

const PoiStack: React.FC<IPoiStack> = (props: IPoiStack) => {
  const renderIcons = () => {
    return Array.from(props.icons)
      .splice(0, MAX_NUMBER_OF_VISIBLE_POI_ICONS)
      .map((icon, key) => (
        // <Icon iconClass="poi-stack__icon" iconType={IconType.icon_poi} iconUrl={icon} />
        <Icon key={key} iconClass="poi-stack__icon" iconType={icon} />
      ))
  }

  return (
    <div className={ClassNames("poi-stack-wrapper", props.className)}>
      <div className="poi-stack" onClick={props.showPoiStackList}>
        <div className="poi-stack__icons-container"></div>
        {renderIcons()}
        {props.amount > MAX_NUMBER_OF_VISIBLE_POI_ICONS && (
          <Icon iconClass="poi-stack__icon" iconType={IconType.icon_options_more} />
        )}
      </div>
      <span className="poi-stack__amount">{props.amount}</span>
    </div>
  )
}

export default PoiStack
