export const SET_SETTINGS_INITIAL_LOCATION = "SET_SETTINGS_INITIAL_LOCATION"
export const SET_SETTINGS_OPEN_STATE = "SET_SETTINGS_OPEN_STATE"
export const EMPTY_ROUTE = "#"

export type ReduxInitAction = { type: "@@INIT" }

interface SetInitialLocation {
  type: typeof SET_SETTINGS_INITIAL_LOCATION
  payload: string
}

interface SetOpenState {
  type: typeof SET_SETTINGS_OPEN_STATE
  isOpen: boolean
}

export interface StatusBarSettingsState {
  initialLocation: string
  isOpen: boolean
}

export type StatusSettingsType = SetInitialLocation | SetOpenState
