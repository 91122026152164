import {
  LocationState,
  UpdateLocationHistoryAction,
  UPDATE_LOCATION_HISTORY,
  SetHomeButtonUrlAction,
  SET_HOME_BUTTON_URL,
  SET_PAGE_TRANSITION_ANIMATION,
  SetPageTransitionAnimationAction,
  SET_SOC,
  SetSocAction,
  ShowAppleCarplayAction,
  SHOW_APPLE_CARPLAY,
  SetIncomingCallPopupVisibiity,
  SET_INCOMING_CALL_POPUP_VISIBILITY,
  SetOngoingCall,
  SET_ONGOING_CALL,
  SetVoiceSearch,
  SET_VOICE_SEARCH
} from "./types"

const locationHistoryInitialState: LocationState = {
  location: {
    pathname: "/",
    search: "",
    state: "",
    hash: ""
  },
  source: "center"
}

const homeButtonUrlIntialState: string = "/center/myScreen"

const setPageTransitionAnimationIntialState: string = "page-transition-fade"

const socInitialState: number = 75

const appleCarplayInitialState: boolean = true

const incomingCallPopupVisibilityIntialState: boolean = false

const ongoingCallIntialState: boolean = false

const voiceSearchIntialState: boolean = false

export function locationHistoryReducer(state = locationHistoryInitialState, action: UpdateLocationHistoryAction) {
  switch (action.type) {
    case UPDATE_LOCATION_HISTORY:
      return { ...state, location: action.location, source: action.source }
    default:
      return state
  }
}

export function homeButtonUrlReducer(state = homeButtonUrlIntialState, action: SetHomeButtonUrlAction) {
  switch (action.type) {
    case SET_HOME_BUTTON_URL:
      return action.url
    default:
      return state
  }
}

export function pageTransitionAnimationReducer(
  state = setPageTransitionAnimationIntialState,
  action: SetPageTransitionAnimationAction
) {
  switch (action.type) {
    case SET_PAGE_TRANSITION_ANIMATION:
      return action.animationName
    default:
      return state
  }
}

export function socReducer(state = socInitialState, action: SetSocAction) {
  switch (action.type) {
    case SET_SOC:
      return action.soc
    default:
      return state
  }
}

export function appleCarplayReducer(state = appleCarplayInitialState, action: ShowAppleCarplayAction) {
  switch (action.type) {
    case SHOW_APPLE_CARPLAY:
      return action.show
    default:
      return state
  }
}

export function incomingCallPopupVisibilityReducer(
  state = incomingCallPopupVisibilityIntialState,
  action: SetIncomingCallPopupVisibiity
) {
  switch (action.type) {
    case SET_INCOMING_CALL_POPUP_VISIBILITY:
      return action.show
    default:
      return state
  }
}

export function ongoingCallReducer(state = ongoingCallIntialState, action: SetOngoingCall) {
  switch (action.type) {
    case SET_ONGOING_CALL:
      return action.ongoing
    default:
      return state
  }
}

export function voiceSearchReducer(state = voiceSearchIntialState, action: SetVoiceSearch) {
  switch (action.type) {
    case SET_VOICE_SEARCH:
      return action.voiceSearch
    default:
      return state
  }
}
