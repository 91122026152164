import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"
import { WarnMessage } from "./messages/warn-messages"
import { GenericMessage } from "./messages/generic-messages"

export const SET_ACTIVE_NOTIFICATION = "SET_ACTIVE_NOTIFICATION"
export const CLOSE_QUICK_ACTION_OF_ACTIVE_NOTIFICATION = "CLOSE_QUICK_ACTION_OF_ACTIVE_NOTIFICATION"

export type ReduxInitAction = { type: "@@INIT" }

export type setActiveNotificationAction = {
  type: typeof SET_ACTIVE_NOTIFICATION
  notification: WarnMessage | GenericMessage
}

export type closeQuickActionOfActiveNotificationAction = {
  type: typeof CLOSE_QUICK_ACTION_OF_ACTIVE_NOTIFICATION
}

export type ActiviveNotificationState = {
  notification: WarnMessage | GenericMessage
}

export type Action = ReduxInitAction | setActiveNotificationAction | closeQuickActionOfActiveNotificationAction

export type Store = ReduxStore<ActiviveNotificationState, Action>

export type Dispatch = ReduxDispatch<Action>

export type ActiveNotificationType = setActiveNotificationAction | closeQuickActionOfActiveNotificationAction
