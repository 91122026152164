import { FilterSettingType, SET_ACTIVE_FILTER } from "./types"

const initialState: string = "all"

export function activeFilterReducer(state = initialState, action: FilterSettingType): string {
  switch (action.type) {
    case SET_ACTIVE_FILTER:
      return action.filter
    default:
      return state
  }
}
