import { createStore, combineReducers } from "redux"
import { activeSourceReducer } from "@pag/center/components/quickFilter/quickFilterIconWithSpeaker/reducer"
import { activeFilterReducer } from "@pag/center/components/quickFilter/quickFilterIconWithIndicator/reducer"
import {
  locationHistoryReducer,
  homeButtonUrlReducer,
  pageTransitionAnimationReducer,
  socReducer,
  appleCarplayReducer,
  incomingCallPopupVisibilityReducer,
  ongoingCallReducer,
  voiceSearchReducer
} from "@pag/center/views/centerMainScreen/reducer"
import { activeSubTabItemReducer } from "@pag/center/components/subTabBarRouting/subTabBarRoutingItem/reducer"
import { songReducer } from "@pag/center/components/player/audioPlayer/reducer"
import { titlePlayTimeReducer } from "@pag/center/components/player/audioPlayer/html5AudioWrapper/reducer"
import { searchBarInputReducer } from "@pag/center/components/speller/keyboard/reducer"
import { activeNotificationReducer } from "@pag/center/views/notificationCenter/reducer"
import { activeMainTabBarItemReducer, closeWizardReducer } from "@pag/center/components/mainTabBar/reducer"
import { driveMainScreenListItemReducer, driveModeReducer } from "@pag/center/views/carScreen/driveMainScreen/reducer"
import { MainStateReducer } from "../../redux/reducer/mainState/reducer"
import { navigationReducer, mapReducer, poiReducer } from "@pag/center/views/navigationScreen/mapMain/reducer"
import { statusBarSettingsReducer } from "@pag/center/components/statusBar/reducer"
import { balanceAndFaderReducer } from "@pag/center/views/mediaPlayer/mediaSound/balanceAndFader/reducer"
import { playbackFastForwarReducer } from "@pag/center/components/player/audioPlayer/audioPlayerProgressBar/reducer"
import { welcomeWizardReducer } from "@pag/center/views/welcomeScreen/reducer"
import { settingsReducer } from "@pag/center/views/settingsScreen/reducer"
import { myScreenReducer } from "@pag/center/views/myScreen/reducer"
import { destInputReducer } from "@pag/center/views/navigationScreen/reducer"
import { homeLinkReducer } from "@pag/center/views/homeLinkScreen/reducer"
import { phoneSearchReducer } from "@pag/center/views/phoneScreen/reducer"
import { mediaReducer } from "@pag/center/views/mediaPlayer/reducer"
import { launcherTileReducer } from "@pag/center/views/launcherConfig/reducer"

const rootReducer = combineReducers({
  mainState: MainStateReducer,
  quickFilterSource: activeSourceReducer,
  quickFilterActive: activeFilterReducer,
  subTabBarItem: activeSubTabItemReducer,
  audioPlayer: songReducer,
  titlePlayTime: titlePlayTimeReducer,
  locationHistory: locationHistoryReducer,
  homeButtonUrl: homeButtonUrlReducer,
  pageAnimationTrasition: pageTransitionAnimationReducer,
  searchBarInput: searchBarInputReducer,
  activeNotification: activeNotificationReducer,
  driveMainScreenListItem: driveMainScreenListItemReducer,
  mainTabBarItem: activeMainTabBarItemReducer,
  closeWizard: closeWizardReducer,
  navigation: navigationReducer,
  map: mapReducer,
  poi: poiReducer,
  statusBarSettings: statusBarSettingsReducer,
  balanceAndFader: balanceAndFaderReducer,
  driveMode: driveModeReducer,
  soc: socReducer,
  showAppleCarplay: appleCarplayReducer,
  songFastForwarTime: playbackFastForwarReducer,
  welcomeWizard: welcomeWizardReducer,
  incomingCallPopupVisibility: incomingCallPopupVisibilityReducer,
  ongoingCall: ongoingCallReducer,
  settingsScreen: settingsReducer,
  myScreen: myScreenReducer,
  voiceSearch: voiceSearchReducer,
  destInput: destInputReducer,
  homeLink: homeLinkReducer,
  phoneSearch: phoneSearchReducer,
  media: mediaReducer,
  launcher: launcherTileReducer
})

export type AppState = ReturnType<typeof rootReducer>

export default function configureStore() {
  const store = createStore(
    rootReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  )

  return store
}
