import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"
import { ESourceNames } from "@pag/center/components/quickFilter/quickFilterIconWithSpeaker/types"

export const SET_SONG = "SET_SONG"
export const PLAY_SONG = "PLAY_SONG"
export const SET_CURRENT_BROWSING_CATEGORY = "SET_CURRENT_BROWSING_CATEGORY"
export const SET_CURRENT_BROWSING_CATEGORY_APPLE = "SET_CURRENT_BROWSING_CATEGORY_APPLE"
export const DISABLE_ENTRY_ANIMATIONS = "DISABLE_ENTRY_ANIMATIONS"
export const TRIGGER_LIST_SWITCH_ANIMATION = "TRIGGER_LIST_SWITCH_ANIMATION"

export type ReduxInitAction = { type: "@@INIT" }

export interface song {
  title: string
  artist: string
  album: string
  displayed: boolean
  cover: string
  filename: string
  genre: string
}

export interface category {
  name: string
  categoryType: string
}

export type setSongAction = {
  type: typeof SET_SONG
  song: song
  source: ESourceNames
  playing: boolean
  listFilter: string
}

export type playSongAction = {
  type: typeof PLAY_SONG
  song: song
  source: ESourceNames
  playing: boolean
}

export type setCurrentBrowsingCategoryAction = {
  type: typeof SET_CURRENT_BROWSING_CATEGORY
  category: category
}

export type setCurrentBrowsingCategory_AppleAction = {
  type: typeof SET_CURRENT_BROWSING_CATEGORY_APPLE
  category: category
}

export type disableEntryAnimationsAction = {
  type: typeof DISABLE_ENTRY_ANIMATIONS
  disabled: boolean
}

export type trigglerListSwitchAnimationAction = {
  type: typeof TRIGGER_LIST_SWITCH_ANIMATION
  animation: boolean
}

export type audioPlayerState = {
  songs_USB: song[]
  songs_radio: song[]
  songs_online_radio: song[]
  playing: boolean
  currentBrowsingCategory: category
  currentBrowsingCategory_Apple: category
  entryAnimationsDisabled: boolean
  listFilter: string
  listSwitchAnimation: boolean
}

export type Action =
  | ReduxInitAction
  | setSongAction
  | playSongAction
  | setCurrentBrowsingCategoryAction
  | disableEntryAnimationsAction
  | setCurrentBrowsingCategory_AppleAction
  | trigglerListSwitchAnimationAction

export type Store = ReduxStore<audioPlayerState, Action>

export type Dispatch = ReduxDispatch<Action>

export type SongActionType = setSongAction | playSongAction
