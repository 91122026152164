import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER"

export type ReduxInitAction = { type: "@@INIT" }

export type setActiveFilterAction = {
  type: typeof SET_ACTIVE_FILTER
  filter: string
}

export type FilterState = {
  filter: string
}

export type Action = ReduxInitAction | setActiveFilterAction

export type Store = ReduxStore<FilterState, Action>

export type Dispatch = ReduxDispatch<Action>

export type FilterSettingType = setActiveFilterAction
