import {
  StatusBarSettingsState,
  StatusSettingsType,
  SET_SETTINGS_INITIAL_LOCATION,
  SET_SETTINGS_OPEN_STATE
} from "./types"

const initialStatelocation: StatusBarSettingsState = {
  initialLocation: "",
  isOpen: false
}

export function statusBarSettingsReducer(
  state = initialStatelocation,
  action: StatusSettingsType
): StatusBarSettingsState {
  switch (action.type) {
    case SET_SETTINGS_INITIAL_LOCATION:
      return {
        ...state,
        initialLocation: action.payload
      }
    case SET_SETTINGS_OPEN_STATE:
      return {
        ...state,
        isOpen: action.isOpen
      }
    default:
      return state
  }
}
