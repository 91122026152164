import React, { useRef, useEffect, useState } from "react"
import cx from "classnames"

import ScrollPositionIndicatorPixel from "./scrollPositionIndicatorPixel/ScrollPositionIndicatorPixel"

import "./list.scss"

interface IList {
  header?: any
  content: any
  footer?: any
  scrollSnapProximity?: boolean
  isScrollbarVisible?: boolean
  className?: string
  headerOutsideList?: boolean
  showAntiDoubleLineSpacer?: boolean
  disableScroll?: boolean
  isSlideOutList?: boolean
  resetScrollbarPosition?: boolean
}

const List: React.FunctionComponent<IList> = ({
  header,
  content,
  footer,
  scrollSnapProximity,
  isScrollbarVisible,
  className,
  headerOutsideList,
  showAntiDoubleLineSpacer,
  disableScroll,
  isSlideOutList,
  resetScrollbarPosition,
  children
}) => {
  const listContent = useRef<HTMLSpanElement>(null)
  const listContainer = useRef<HTMLDivElement>(null)
  const [TotalListHeight, setTotalListHeight] = useState(0)
  const [handleBarPos, sethandleBarPos] = useState(0)
  const [handleBarHeight, sethandleBarHeight] = useState(0)
  const [scrollTopMax, setscrollTopMax] = useState<number>(0)
  const [scrollbarVisible, setScrollbarVisible] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      if (listContent.current && listContent.current.children[0]) {
        setTotalListHeight(listContent.current.children[0].scrollHeight)
      }

      if (listContent.current && listContent.current.children[0] && listContainer.current) {
        if (!scrollTopMax) setscrollTopMax(listContent.current.getBoundingClientRect().top)
        let visibleListHeight: number = 0
        setTotalListHeight(listContent.current.children[0].scrollHeight)
        let scrollbarHeight = listContainer.current.clientHeight - 24 //account for padding
        visibleListHeight = listContainer.current.clientHeight
        let handleBarCompleteHeight = Math.round((visibleListHeight / TotalListHeight) * scrollbarHeight)
        sethandleBarHeight(handleBarCompleteHeight > 12 ? handleBarCompleteHeight : 12) //minimum size

        let scrollTop = listContent.current.getBoundingClientRect().top * -1 + scrollTopMax
        let scrollPerCent = 100 / (TotalListHeight / scrollTop)
        let scrollBarPos = ((scrollbarHeight - handleBarHeight + handleBarHeight) / 100) * scrollPerCent
        sethandleBarPos(scrollBarPos)
      }
    }, 20)
  })

  useEffect(() => {
    if (isSlideOutList) {
      setTimeout(() => {
        setScrollbarVisible(true)
      }, 300)
    } else {
      setScrollbarVisible(true)
    }
  }, [isSlideOutList])
  //is needed to fix scrollbar on playview slideout at media screen
  useEffect(() => {
    if (listContent.current) setscrollTopMax(listContent.current.getBoundingClientRect().top)
    if (resetScrollbarPosition) sethandleBarPos(0)
  }, [resetScrollbarPosition])

  const showScrollPositionIndicatorPixel = () => {
    if (listContainer.current) {
      if (scrollbarVisible && TotalListHeight > listContainer.current.clientHeight) {
        return <ScrollPositionIndicatorPixel handleBarPosition={handleBarPos} handleBarHeight={handleBarHeight} />
      }
    }
  }

  // since the calculation in the angular project is quite complex, you'll find some magic numbers here that i couldn't figure out
  const handleScroll = () => {
    if (listContent.current && listContainer.current) {
      let scrollbarHeight = listContainer.current.clientHeight - 24 //account for padding
      let scrollTop = listContent.current.getBoundingClientRect().top * -1 + 224
      let scrollPerCent = 100 / (TotalListHeight / scrollTop)
      let scrollBarPos = ((scrollbarHeight - handleBarHeight + handleBarHeight) / 100) * scrollPerCent
      sethandleBarPos(scrollBarPos)
    }
  }

  const showHeader = () => {
    if (header) {
      return <div className="list__list-header">{header}</div>
    }
  }
  return (
    <div className={cx("list", className, { "header-outside-list": headerOutsideList })}>
      {showHeader()}

      {/* 
            // the invisible-overlay-to-stop-click-through is duplicated, because this one up here
            //     blocks interaction with the header and the one inside the everything-but-static-header-container
            //     blocks interaction with other list items  */}

      <div onScroll={() => handleScroll()} ref={listContainer} className="list__everything-but-static-header-container">
        <div
          className={cx(
            "list__container",
            { "list--scroll-snap-proximity": scrollSnapProximity },
            { "list__container--with-scroll-bar": scrollbarVisible },
            { "list__container--no-scrolling": disableScroll }
          )}
        >
          <div className="list__content-container">
            <span ref={listContent} className="list__content-container">
              {content}
            </span>
            <div className="solid-lines"></div>

            <span className="list__content-container"></span>

            <div className="solid-lines"></div>
            {footer}

            {showAntiDoubleLineSpacer ? (
              <div className="list__spaceholder-to-avoid-double-lines-with-last-item"></div>
            ) : null}
          </div>
        </div>

        {/* < !--normal scrollbar-- > */}

        {showScrollPositionIndicatorPixel()}
      </div>

      {children}
    </div>
  )
}

export default List
