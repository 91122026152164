import React from "react"
import ClassNames from "classnames"
import "./infoMessage.scss"
import Icon from "../icon/Icon"
import I18nLabel from "../I18nLabel/I18nLabel"

interface InfoMessageProps {
  className?: string
  developerText: string
  useFullScreenWrapper: boolean
  icon?: string
  children?: any // only buttons
  interpolationKeys?: Object
}

const InfoMessage: React.FC<InfoMessageProps> = (props) => {
  return (
    <div className={ClassNames(props.className, "info-message-wrapper")}>
      {props.useFullScreenWrapper ? (
        <div className="bg-dotgrid__wrapper">
          <div className="bg-dotgrid bg-dotgrid__mask-highlight bg-dotgrid__centered" />
        </div>
      ) : null}
      <div className="info">
        {props.icon ? <Icon iconClass="info__icon" iconType={props.icon} /> : null}

        {props.developerText ? (
          <div className="info__message">
            <I18nLabel interpolationKeys={props.interpolationKeys} text={props.developerText} />
          </div>
        ) : null}
        {/*
  <div *ngIf="infoDetailMessageEnum || developerDetailText"
       class="info__message">
    <pag3-i18n-label [id]="infoDetailId"
                     ttIgnore
                     [ttIgnoreDynamicValue]="!(developerDetailText || infoDetailMessageEnum)"
                     [idEnumType]="infoDetailMessageEnum"
                     [developerText]="developerDetailText">
    </pag3-i18n-label>
  </div>

    */}
      </div>
      {props.children && <div className="buttons">{props.children}</div>}
    </div>
  )
}

export default InfoMessage
