import moment from "moment"
import { IconType } from "@pag/center/components/icon/iconType"

export enum FormattedDurationEnum {
  default = "",
  positive = "+",
  negative = "-"
}

function socIconColor(soc: number | undefined): string | undefined {
  if (soc !== undefined) {
    if (soc <= 7) {
      return "charge-low"
    }
    if (soc < 20) {
      return "charge-medium"
    }
  }
}

function socIconType(soc: number | undefined): string | undefined {
  if (soc === undefined || soc <= 0) {
    return IconType.icon_soc_destination_blank
  }
  if (soc <= 7) {
    return IconType.icon_soc_destination_0
  }
  if (soc < 20) {
    return IconType.icon_soc_destination_1
  }
  if (soc < 40) {
    return IconType.icon_soc_destination_2
  }
  if (soc < 60) {
    return IconType.icon_soc_destination_3
  }
  if (soc < 80) {
    return IconType.icon_soc_destination_4
  }
  return IconType.icon_soc_destination_5
}

function range(target: number, min: number, max: number, out_min: number, out_max: number) {
  var t_val = ((target - min) * (out_max - out_min)) / (max - min) + out_min
  if (t_val > Math.max(out_max, out_min)) {
    t_val = Math.max(out_max, out_min)
  }
  if (t_val < Math.min(out_max, out_min)) {
    t_val = Math.min(out_max, out_min)
  }
  return t_val
}

function naviDistanceMetric(distance: number) {
  if (distance > 1000) {
    return `${Math.round(distance / 1000)} km`
  }
  return `${Math.ceil(distance / 10) * 10} m`
}

function formattedDuration(number: number, numberConfiguration: FormattedDurationEnum = FormattedDurationEnum.default) {
  if (number < 3600) {
    const time = moment.utc(number * 1000).format("m")
    return numberConfiguration + time + " min"
  }
  const time = moment.utc(number * 1000).format("hh:mm")

  return numberConfiguration + time + " h"
}

export { socIconColor, socIconType, range, naviDistanceMetric, formattedDuration }
