import _ from "lodash"
import * as turf from "@turf/turf"

import { bearingBetween } from "./helpers"

import {
  Coordinate,
  MapBoxApiResponse,
  RouteData,
  Leg,
  Step,
  AlternativeRouteInfo,
  AlternativeRoute,
  TravelData,
  AlternativeRoutePayload,
  NavigationUpdatePayload
} from "./types"
import {
  updateNavigationPayload,
  initNavigationPayload,
  Route,
  ManeuverType
} from "@pag/center/views/navigationScreen/mapMain/types"

import * as FrankfurtData from "./frankfurt/frankfurtData"
import * as StuttgartData from "./stuttgart/stuttgartData"

const mapBoxAccessToken: string =
  "pk.eyJ1Ijoia2xhcHNrYWxsaSIsImEiOiJjanNwdjh2dmswNXh4NDNtbnUxY3MzZGhiIn0.K5hPtQ67iab8xC_n4qRttg"
const mapBoxDirectionsAPI: string = "https://api.mapbox.com/directions/v5/mapbox/driving/"

enum City {
  FRANKFURT,
  STUTTGART
}

function buildMapBoxUrl(coordinates: Coordinate[]): string {
  const routeOptions: string = "steps=true&geometries=geojson&alternatives=true"
  const coordinatesQueryString: string = coordinates.map((coordinate) => coordinate.join(",")).join(";")

  return `${mapBoxDirectionsAPI}${coordinatesQueryString}?${routeOptions}&access_token=${mapBoxAccessToken}`
}

interface INavigationService {
  init: (
    updateNavigationAction: (payload: updateNavigationPayload) => void,
    stopNavigationAction: () => void,
    initNavigationAction: (payload: initNavigationPayload) => void
  ) => Promise<any>
}

class NavigationService implements INavigationService {
  private activeCity = City.STUTTGART
  private config = this.getActiveConfig()

  // @ts-ignore
  private navigationUpdateInterval: NodeJS.Timeout
  private alternativeRoutes: AlternativeRoute[] = this.config.alternativeRoutesInfo.map(
    (ari: AlternativeRouteInfo) => ({
      ...ari,
      route: [],
      maneuvers: [],
      distance: 0,
      duration: 0
    })
  )
  private finished: boolean = false
  private index: number = 0 // active alternative-routes index
  // Redux actions
  private updateNavigationAction: (payload: updateNavigationPayload) => void
  private stopNavigationAction: () => void
  private initNavigationAction: (payload: initNavigationPayload) => void

  public getActiveConfig() {
    if (this.activeCity === City.FRANKFURT) {
      return FrankfurtData
    } else if (this.activeCity === City.STUTTGART) {
      return StuttgartData
    }

    return FrankfurtData
  }

  /**
   * Gt route information
   */
  public async init(
    updateNavigationAction: (payload: updateNavigationPayload) => void,
    stopNavigationAction: () => void,
    initNavigationAction: (payload: initNavigationPayload) => void
  ) {
    this.updateNavigationAction = updateNavigationAction
    this.stopNavigationAction = stopNavigationAction
    this.initNavigationAction = initNavigationAction

    await this.getRoutes()

    this.publishNavigationInitInfo()
  }

  /**
   * Retrieve route information from MapBoxAPI.
   * Extract route, distance and maneuvers.
   */
  private async getRoutes(): Promise<any> {
    for (let i = 0; i < this.alternativeRoutes.length; i += 1) {
      const response: any = await fetch(buildMapBoxUrl(this.alternativeRoutes[i].coordinates))
      const responseJSON: MapBoxApiResponse = await response.json()

      const data: RouteData = responseJSON.routes[0]
      const legs: Leg[] = data.legs
      const steps: Step[] = _.flatten(legs.map((leg) => leg.steps))

      this.alternativeRoutes[i].route = _.flatten(steps.map((step) => step.geometry.coordinates))

      this.alternativeRoutes[i].distance = data.distance
      this.alternativeRoutes[i].maneuvers = steps
        .map((step) => step.maneuver)
        .map((maneuver: ManeuverType, idx) => {
          const newManeuver: ManeuverType = {
            location: maneuver.location,
            type: maneuver.type
          }

          if (maneuver.type === "arrive") {
            newManeuver.modifier = idx === steps.length - 1 ? "destination" : "stopover"
          } else if (maneuver.type === "depart") {
            newManeuver.modifier = "continue"
          } else if (maneuver.modifier) {
            newManeuver["modifier"] = maneuver.modifier // only some maneuver types have modifieres
          }

          return newManeuver
        })
      this.alternativeRoutes[i].duration =
        ((this.alternativeRoutes[i].trace[this.alternativeRoutes[i].trace.length - 1].timestamp -
          this.alternativeRoutes[i].trace[0].timestamp) /
          1000) *
        2
    }
  }

  /**
   * Publishing navigation information needed on start-up
   * e.g. route information for all alternative routes
   * to display the route in the map and initial coordinates.
   */
  private publishNavigationInitInfo(): void {
    const activeRouteId = this.alternativeRoutes[0].id
    const currentPosition: [number, number] = [
      this.alternativeRoutes[0].trace[0].center.lng,
      this.alternativeRoutes[0].trace[0].center.lat
    ]
    const speed = this.alternativeRoutes[0].trace[0].speed
    const bearing = this.alternativeRoutes[0].trace[0].bearing
    const alternativeRoutes: Route[] = this.alternativeRoutes.map((alternativeRoute: AlternativeRoute) => {
      const travelData: TravelData = {
        rrd: alternativeRoute.distance,
        eta: alternativeRoute.duration,
        rtt: alternativeRoute.duration,
        soc: alternativeRoute.soc,
        trafficDelay: 0
      }

      const alternativeRoutePayload: AlternativeRoutePayload = {
        id: alternativeRoute.id,
        bounds: alternativeRoute.bounds,
        route: alternativeRoute.route,
        totalDistance: alternativeRoute.distance,
        totalDuration: alternativeRoute.duration,
        travelData,
        driveMode: alternativeRoute.driveMode,
        climateSetting: alternativeRoute.climateSetting
      }

      return alternativeRoutePayload
    })

    const payload: initNavigationPayload = {
      alternativeRoutes,
      activeRouteId,
      currentPosition,
      speed,
      bearing
    }
    this.initNavigationAction(payload)
  }

  /**
   * Broadcasts stop-online-navigation event and
   * stops interval with navigation updates
   */
  private stopNavigation(): void {
    this.finished = true
    this.stopNavigationAction()
    clearInterval(this.navigationUpdateInterval)
  }

  /**
   * Stops navigation.
   */
  public stop(): void {
    this.stopNavigation()
  }

  /**
   * Start navigation
   */
  public async start(activeRouteId: string): Promise<any> {
    // clear active navigation
    this.finished = true
    clearInterval(this.navigationUpdateInterval)

    this.finished = false
    // Set index for active alternative route with payload value (id)
    this.index = _.findIndex(this.alternativeRoutes, ["id", activeRouteId])
    // broadcast start-online-navigation event
    const payload: NavigationUpdatePayload = {
      currentPosition: [
        this.alternativeRoutes[this.index].trace[0].center.lng,
        this.alternativeRoutes[this.index].trace[0].center.lat
      ],
      bearing: this.alternativeRoutes[this.index].trace[0].bearing,
      nextManeuver: this.alternativeRoutes[this.index].maneuvers[0],
      speed: this.alternativeRoutes[this.index].trace[0].speed,
      acceleration: this.alternativeRoutes[this.index].trace[0].acceleration,
      remainingDistance: this.alternativeRoutes[this.index].distance,
      remainingDuration: this.alternativeRoutes[this.index].duration
    }
    // this.startNavigationAction()
    this.navigationUpdateInterval = await this.updateNavigation()
  }

  /**
   * Publishes navigation update event every 200ms.
   * The message sent contains the current and next position,
   * the current speed, the next maneuver, the current bearing
   * and the remaining distance as well as the remaining duration.
   */
  private async updateNavigation() {
    let nextManeuverIndex: number = 0
    let remainingDistance: number = this.alternativeRoutes[this.index].distance
    let cur_seg = 0
    let cur_seg_start: Coordinate = [0, 0]
    let cur_seg_end: Coordinate
    let cur_seg_distance: number = 0
    var cur_seg_dir: number
    var cur_acceleration: number = 0
    var cur_speed: number = (0 / 3600) * 1000 // km/h to m/s
    var prev_time: number
    var cur_time: number

    const seg_update = () => {
      if (cur_seg > this.alternativeRoutes[this.index].trace.length - 2) {
        this.stopNavigation()
      } else {
        const currentCenterPositionObject = this.alternativeRoutes[this.index].trace[cur_seg].center
        const nextCenterPositionObject = this.alternativeRoutes[this.index].trace[cur_seg + 1].center
        cur_seg_start = [currentCenterPositionObject.lng, currentCenterPositionObject.lat]
        cur_seg_end = [nextCenterPositionObject.lng, nextCenterPositionObject.lat]
        cur_seg_distance = turf.distance(cur_seg_start, cur_seg_end) * 1000
        const bearing = bearingBetween(cur_seg_start, cur_seg_end)
        cur_seg_dir = bearing === 0 ? this.alternativeRoutes[this.index].trace[cur_seg].bearing : bearing
      }
    }

    // Initialize variables
    seg_update()

    let cur_waypoint: Coordinate = cur_seg_start
    let next_waypoint: Coordinate
    let left_in_seg: number = cur_seg_distance
    let the_distance: number

    const sendUpdate = () => {
      const payload: updateNavigationPayload = {
        currentPosition: cur_waypoint,
        nextManeuver: this.alternativeRoutes[this.index].maneuvers[nextManeuverIndex],
        bearing: cur_seg_dir,
        speed: cur_speed,
        acceleration: cur_acceleration,
        remainingDistance,
        remainingDuration:
          (remainingDistance * this.alternativeRoutes[this.index].duration) /
          this.alternativeRoutes[this.index].distance
      }

      if (this.finished || remainingDistance < 0) {
        // stop sending navigation updates
        this.stopNavigationAction() // TODO call stop action?
      } else {
        this.updateNavigationAction(payload)
      }
    }

    const nextManeuverUpdateCheck = () => {
      if (
        turf.distance(cur_seg_start, this.alternativeRoutes[this.index].maneuvers[nextManeuverIndex].location) >
        turf.distance(
          [
            this.alternativeRoutes[this.index].trace[Math.max(0, cur_seg - 1)].center.lng, // hacky
            this.alternativeRoutes[this.index].trace[Math.max(0, cur_seg - 1)].center.lat // hacky
          ],
          this.alternativeRoutes[this.index].maneuvers[nextManeuverIndex].location
        )
      ) {
        nextManeuverIndex = Math.min(nextManeuverIndex + 1, this.alternativeRoutes[this.index].maneuvers.length - 1)
      }
    }

    const calcTimeDiff = () => {
      if (!prev_time) {
        return 200 // milliseconds
      }

      const datetime = new Date()
      cur_time = datetime.getTime()
      // @ts-ignore
      return cur_time - prev_time
    }

    const updateSpeedAndAcceleration = () => {
      if (this.alternativeRoutes[this.index].trace[cur_seg]) {
        cur_speed = this.alternativeRoutes[this.index].trace[cur_seg].speed
        cur_acceleration = this.alternativeRoutes[this.index].trace[cur_seg].acceleration
      } else {
        // cur_speed = 0
        // Run out of segments / End of trace
        this.stopNavigation()
        return
      }
    }

    return setInterval(() => {
      updateSpeedAndAcceleration()

      try {
        nextManeuverUpdateCheck()
      } catch (e) {
        // No more next maneuvers = 'last' maneuever was 'destination'
        this.stopNavigation()
        return
      }

      const timediff: number = calcTimeDiff()
      the_distance = (cur_speed * timediff) / 1000 // meter
      remainingDistance -= the_distance

      // Check if left distance is enough to go
      if (left_in_seg > the_distance) {
        // @ts-ignore
        next_waypoint = turf.destination(cur_waypoint, the_distance / 1000, cur_seg_dir).geometry.coordinates
        sendUpdate()
      } else {
        the_distance = (cur_speed * timediff) / 1000 - left_in_seg

        // go to next segment
        cur_seg++
        seg_update()

        left_in_seg = cur_seg_distance
        cur_waypoint = cur_seg_start
        // @ts-ignore
        next_waypoint = turf.destination(cur_waypoint, the_distance / 1000, cur_seg_dir).geometry.coordinates

        sendUpdate()
      }

      left_in_seg -= the_distance
      cur_waypoint = next_waypoint
      prev_time = new Date().getTime()
    }, 200)
  }
}

export default new NavigationService()
