import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

export const SET_BALANCE = "SET_BALANCE"
export const SET_FADE = "SET_FADE"

export type ReduxInitAction = { type: "@@INIT" }

interface SetBalance {
  type: typeof SET_BALANCE
  payload: number
}

interface SetFade {
  type: typeof SET_FADE
  payload: number
}

export interface BalanceAndFaderState {
  balance: number
  fade: number
}

export type Action = ReduxInitAction | SetBalance | SetFade

export type Store = ReduxStore<BalanceAndFaderState, Action>

export type Dispatch = ReduxDispatch<Action>

export type BalanceAndFaderType = SetBalance | SetFade
