import { Store as ReduxStore, Dispatch as ReduxDispatch } from 'redux';

export const SET_ACTIVE_MAIN_TAB_BAR_ITEM = 'SET_ACTIVE_MAIN_TAB_BAR_ITEM'

export const SET_CLOSE_WIZARD = 'SET_CLOSE_WIZARD'

export type ReduxInitAction = { type: '@@INIT' };

export type SetActiveMainTabBarItemAction = {
    type: typeof SET_ACTIVE_MAIN_TAB_BAR_ITEM
    item: string
}

export type MainTabBarState = string

export type Action = ReduxInitAction | SetActiveMainTabBarItemAction;

export type Store = ReduxStore<MainTabBarState, Action>;

export type Dispatch = ReduxDispatch<Action>;

export type SetCloseWizard = {
    type: typeof SET_CLOSE_WIZARD
    close: boolean
}

export type CloseWizardState = boolean;

