import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

export const SET_VISIBLE_SETTINGS_LIST = "SET_VISIBLE_SETTINGS_LIST"
export const SET_CURRENT_RADIO_BUTTON_LIST_ITEM = "SET_CURRENT_RADIO_BUTTON_LIST_ITEM"
export const UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE = "UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE"

export type ReduxInitAction = { type: "@@INIT" }

export type setVisibleSettingsListAction = {
  type: typeof SET_VISIBLE_SETTINGS_LIST
  visibleList: any
}

export type setcurrentRadioButtonListItemAction = {
  type: typeof SET_CURRENT_RADIO_BUTTON_LIST_ITEM
  itemName: string
}

export type updateDetailsScreenOverlayVisibleAction = {
  type: typeof UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE
  visible: boolean
}

export type settingsState = {
  visibleList: any
  currentRadioButtonListItem: string
  detailsScreenOverlayVisible: boolean
}

export type Action =
  | ReduxInitAction
  | setVisibleSettingsListAction
  | setcurrentRadioButtonListItemAction
  | updateDetailsScreenOverlayVisibleAction

export type Store = ReduxStore<settingsState, Action>

export type Dispatch = ReduxDispatch<Action>
