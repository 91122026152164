import { SetActiveMainTabBarItemAction, SET_ACTIVE_MAIN_TAB_BAR_ITEM, MainTabBarState, SetCloseWizard, CloseWizardState, SET_CLOSE_WIZARD } from './types'

export enum EMainTabBarItems {
    CENTER = 'center',
    CAR = 'car',
    NOTIFICATIONS = 'notifications'
}

const initialState: string = EMainTabBarItems.CENTER

export function activeMainTabBarItemReducer(
    state = initialState,
    action: SetActiveMainTabBarItemAction
): MainTabBarState {
    switch (action.type) {
        case SET_ACTIVE_MAIN_TAB_BAR_ITEM:
            return action.item
        default:
            return state;
    }
}

const initialCloseWizzardState = false;

export function closeWizardReducer(
    state = initialCloseWizzardState,
    action: SetCloseWizard
): CloseWizardState {
    switch (action.type) {
        case SET_CLOSE_WIZARD:
            return action.close
        default:
            return state;
    }
}
