import { myScreenState, setShownTilesAction, SET_SHOWN_TILES, MY_SCREEN_TILE_IDS } from "./types"

const initialState: myScreenState = {
  shownTiles: [MY_SCREEN_TILE_IDS.MEDIA_PLAYER, MY_SCREEN_TILE_IDS.NAVIGATION, MY_SCREEN_TILE_IDS.CAR_DRIVE_MODE]
}

export function myScreenReducer(state = initialState, action: setShownTilesAction): myScreenState {
  switch (action.type) {
    case SET_SHOWN_TILES:
      return { ...state, shownTiles: action.shownTiles }

    default:
      return state
  }
}
