import { SearchInputType, SAVE_KEY_INPUT, DELETE_INPUT, SearchBarInputState } from "./types"

const initialState: SearchBarInputState = {
  input: ""
}

export function searchBarInputReducer(state = initialState, action: SearchInputType): SearchBarInputState {
  switch (action.type) {
    case SAVE_KEY_INPUT:
      return {
        input: state.input.concat(action.payload)
      }
    case DELETE_INPUT:
      return {
        input: ""
      }
    default:
      return state
  }
}
