export const ADD_MEDIA_FAVOURITE = "ADD_TO_MEDIA_FAVOURITES"
export const TOGGLE_MEDIA_PLAYVIEW = "TOGGLE_MEDIA_PLAYVIEW"
export const TOGGLE_APPLEID_POPUP = "TOGGLE_APPLEID_POPUP"

export type MediaState = {
  favourites: { name: string; cover: string }[]
  isPlayviewVisible: boolean
  appleIdPopUpVisible: boolean
}

export type AddMediaFavouriteAction = {
  type: typeof ADD_MEDIA_FAVOURITE
  favourite: { name: string; cover: string }
}

export type toggleMediaPlayViewAction = {
  type: typeof TOGGLE_MEDIA_PLAYVIEW
}

export type toggleAppleIDPopUpAction = {
  type: typeof TOGGLE_APPLEID_POPUP
  visible: boolean
}

export type Action = AddMediaFavouriteAction | toggleMediaPlayViewAction | toggleAppleIDPopUpAction
