import { Action, MediaState, ADD_MEDIA_FAVOURITE, TOGGLE_MEDIA_PLAYVIEW, TOGGLE_APPLEID_POPUP } from "./types"

const mediaInitialState: MediaState = {
  favourites: [{ name: "FM 4", cover: "fm4" }],
  isPlayviewVisible: false,
  appleIdPopUpVisible: false
}

export function mediaReducer(state = mediaInitialState, action: Action) {
  switch (action.type) {
    case ADD_MEDIA_FAVOURITE:
      if (state.favourites.filter((fav) => fav.name === action.favourite.name).length > 0) return { ...state }
      else return { ...state, favourites: [...state.favourites, action.favourite] }
    case TOGGLE_MEDIA_PLAYVIEW:
      return { ...state, isPlayviewVisible: !state.isPlayviewVisible }
    case TOGGLE_APPLEID_POPUP:
      return { ...state, appleIdPopUpVisible: action.visible }
    default:
      return state
  }
}
