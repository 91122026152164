import { playbackFastForward, UPDATE_PLAYBACK_TIME, PlaybackTimeActionType } from './types'

const initialState: playbackFastForward = {
    currentPlaybackTime: 0
}

export function playbackFastForwarReducer(
    state = initialState,
    action: PlaybackTimeActionType
): playbackFastForward {
    switch (action.type) {
        case UPDATE_PLAYBACK_TIME:
            return {
                ...state,
                currentPlaybackTime: action.currentPlaybackTime
            }
        default:
            return state;
    }
}
