import React, { FunctionComponent, useState } from "react"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"
import ClassNames from "classnames"
import "./button.scss"
import Icon from "../icon/Icon"

interface IButton {
  labelText?: string
  icon?: string
  iconUrl?: string
  hastText?: boolean
  nonI18n?: string
  buttonId?: string
  className?: string
  disabled?: boolean
  disabledText?: string
  onClick?: () => void
}

const Button: FunctionComponent<IButton> = ({
  labelText = "",
  icon,
  iconUrl,
  hastText,
  nonI18n,
  buttonId,
  className,
  onClick,
  disabled,
  disabledText
}) => {
  const [pressed, setPressed] = useState(false)

  function animateClick() {
    setPressed(true)
    setTimeout(() => {
      setPressed(false)
      if (onClick) onClick()
    }, 500)
  }

  return (
    <div
      className={ClassNames(className, "button__host", { "state-pressed": pressed }, { "state-disabled": disabled })}
      onClick={() => {
        !disabled && animateClick()
      }}
    >
      <div className="animated-bg--growing-circle" />
      <div className="animated-bg--pressed" />
      <div className="button__fading">
        <div className="button__container">
          {icon && <Icon iconClass="button__icon" iconType={icon} />}
          {iconUrl && <Icon iconClass="button__icon" iconUrl={iconUrl} />}
          {(nonI18n || labelText) && (
            <I18nLabel
              text={disabled && disabledText ? disabledText : labelText}
              id={buttonId}
              className="button__text"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Button
