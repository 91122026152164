import { SourceSettingType, SourceState, SET_ACTIVE_SOURCE, SET_PLAYING_SOURCE, ESourceNames } from "./types"

const initialState: SourceState = {
  activeSource: ESourceNames.ONLINE_RADIO,
  playingSource: ESourceNames.ONLINE_RADIO
}

export function activeSourceReducer(state = initialState, action: SourceSettingType): SourceState {
  switch (action.type) {
    case SET_ACTIVE_SOURCE:
      return { ...state, activeSource: action.activeSource }
    case SET_PLAYING_SOURCE:
      return { ...state, playingSource: action.playingSource }
    default:
      return state
  }
}
