export class IconType {
  static icon_apple_carplay = 'icon-apple_carplay';
  static icon_arrow_open_left = 'icon-arrow_open_left';
  static icon_arrow_open_right = 'icon-arrow_open_right';
  static icon_google_search = 'icon-google_search';
  static icon_google_search_inactive = 'icon-google_search_inactive';
  static icon_magnify_glass = 'icon-magnify_glass';
  static icon_microphone = 'icon-microphone';
  static icon_microphone_crossed = 'icon-microphone_crossed';
  static icon_note = 'icon-note';
  static icon_calendar = 'icon-calendar';
  static icon_option_open = 'icon-option_open';
  static icon_person = 'icon-person';
  static icon_processing = 'icon-processing';
  static icon_voice_mail = 'icon-voice_mail';
  static icon_car_limousine = 'icon-car_limousine';
  static icon_car_limousine_niveau = 'icon-car_limousine_niveau';
  static icon_car_limousine_spoiler = 'icon-car_limousine_spoiler';
  static icon_e_sound = 'icon-e_sound';
  static icon_pasm = 'icon-pasm';
  static icon_3D_map = 'icon-3D_map';
  static icon_scale_zoom_auto = 'icon-scale_zoom_auto';
  static icon_guidance_arrow = 'icon-guidance_arrow';
  static icon_scale_zoom_manually = 'icon-scale_zoom_manually';
  static icon_mute = 'icon-mute';
  static icon_my_location = 'icon-my_location';
  static icon_north_arrow = 'icon-north_arrow';
  static icon_readout = 'icon-readout';
  static icon_satellite = 'icon-satellite';
  static icon_destination_flag_arrow = 'icon-destination_flag_arrow';
  static icon_stopover_flag_arrow = 'icon-stopover_flag_arrow';
  static icon_selena = 'icon-selena';
  static icon_speed_limit_60 = 'icon-speed_limit_60';
  static icon_speed_limit_rain = 'icon-speed_limit_rain';
  static icon_view = 'icon-view';
  static icon_weather = 'icon-weather';
  static icon_note_add_favorite = 'icon-note_add_favorite';
  static icon_note_circle = 'icon-note_circle';
  static icon_pause = 'icon-pause';
  static icon_play = 'icon-play';
  static icon_playlist = 'icon-playlist';
  static icon_repeat = 'icon-repeat';
  static icon_replay = 'icon-replay';
  static icon_shuffle = 'icon-shuffle';
  static icon_skip_backward = 'icon-skip_backward';
  static icon_skip_forward = 'icon-skip_forward';
  static icon_spotify = 'icon-spotify';
  static icon_star = 'icon-star';
  static icon_star_outline = 'icon-star_outline';
  static icon_stop_scan = 'icon-stop_scan';
  static icon_tag = 'icon-tag';
  static icon_bluetooth = 'icon-bluetooth';
  static icon_channel = 'icon-channel';
  static icon_cover = 'icon-cover';
  static icon_live = 'icon-live';
  static icon_radio = 'icon-radio';
  static icon_fm_linking_list = 'icon-fm_linking_list';
  static icon_usb = 'icon-usb';
  static icon_art_culture = 'icon-art_culture';
  static icon_charging_station = 'icon-charging_station';
  static icon_destination_flag = 'icon-destination_flag';
  static icon_dls_top = 'icon-dls_top';
  static icon_home = 'icon-home';
  static icon_laptop = 'icon-laptop';
  static icon_microphone_hand = 'icon-microphone_hand';
  static icon_nightlife = 'icon-nightlife';
  static icon_parking = 'icon-parking';
  static icon_sports = 'icon-sports';
  static icon_tour = 'icon-tour';
  static icon_delete_numbers = 'icon-delete_numbers';
  static icon_delete_numbers_arabic = 'icon-delete_numbers_arabic';
  static icon_phone_hang_up = 'icon-phone_hang_up';
  static icon_battery_0 = 'icon-battery_0';
  static icon_battery_1 = 'icon-battery_1';
  static icon_battery_2 = 'icon-battery_2';
  static icon_battery_3 = 'icon-battery_3';
  static icon_battery_4 = 'icon-battery_4';
  static icon_battery_blank = 'icon-battery_blank';
  static icon_cancel = 'icon-cancel';
  static icon_cell_phone = 'icon-cell_phone';
  static icon_group = 'icon-group';
  static icon_information = 'icon-information';
  static icon_phone_add_call = 'icon-phone_add_call';
  static icon_phone_incoming_call = 'icon-phone_incoming_call';
  static icon_phone_missed_call = 'icon-phone_missed_call';
  static icon_phone_outgoing_call = 'icon-phone_outgoing_call';
  static icon_phone_pause_call = 'icon-phone_pause_call';
  static icon_phone_reactivate_call = 'icon-phone_reactivate_call';
  static icon_3g = 'icon-3g';
  static icon_car_front_j1 = 'icon-car_front_j1';
  static icon_car_limousine_sound_off = 'icon-car_limousine_sound_off';
  static icon_cell_phone_crossed = 'icon-cell_phone_crossed';
  static icon_circle_crossed = 'icon-circle_crossed';
  static icon_connect_app = 'icon-connect_app';
  static icon_connectivity = 'icon-connectivity';
  static icon_connectivity_disabled = 'icon-connectivity_disabled';
  static icon_edge = 'icon-edge';
  static icon_etc_active = 'icon-etc_active';
  static icon_etc_error = 'icon-etc_error';
  static icon_geo_localization = 'icon-geo_localization';
  static icon_ionisation = 'icon-ionisation';
  static icon_lte = 'icon-lte';
  static icon_network_0 = 'icon-network_0';
  static icon_network_0_arrow_forward = 'icon-network_0_arrow_forward';
  static icon_network_1 = 'icon-network_1';
  static icon_network_1_arrow_forward = 'icon-network_1_arrow_forward';
  static icon_network_2 = 'icon-network_2';
  static icon_network_2_arrow_forward = 'icon-network_2_arrow_forward';
  static icon_network_3 = 'icon-network_3';
  static icon_network_3_arrow_forward = 'icon-network_3_arrow_forward';
  static icon_network_4 = 'icon-network_4';
  static icon_network_4_arrow_forward = 'icon-network_4_arrow_forward';
  static icon_network_5 = 'icon-network_5';
  static icon_network_5_arrow_forward = 'icon-network_5_arrow_forward';
  static icon_notification = 'icon-notification';
  static icon_parking_assistant = 'icon-parking_assistant';
  static icon_parking_camera = 'icon-parking_camera';
  static icon_privacy = 'icon-privacy';
  static icon_privacy_nad = 'icon-privacy_nad';
  static icon_radio_no_signal = 'icon-radio_no_signal';
  static icon_recording = 'icon-recording';
  static icon_sim_card_privacy = 'icon-sim_card_privacy';
  static icon_wlan = 'icon-wlan';
  static icon_at = 'icon-at';
  static icon_dot = 'icon-dot';
  static icon_enter = 'icon-enter';
  static icon_enter_arabic = 'icon-enter_arabic';
  static icon_globe = 'icon-globe';
  static icon_handwriting = 'icon-handwriting';
  static icon_keyboard = 'icon-keyboard';
  static icon_keyboard_hide = 'icon-keyboard_hide';
  static icon_shift = 'icon-shift';
  static icon_space = 'icon-space';
  static icon_speller_characters = 'icon-speller_characters';
  static icon_speller_delete_numbers = 'icon-speller_delete_numbers';
  static icon_speller_delete_numbers_arabic = 'icon-speller_delete_numbers_arabic';
  static icon_speller_letters = 'icon-speller_letters';
  static icon_speller_numbers = 'icon-speller_numbers';
  static icon_radio_button = 'icon-radio_button';
  static icon_radio_button_checked = 'icon-radio_button_checked';
  static icon_box = 'icon-box';
  static icon_box_checked = 'icon-box_checked';
  static icon_information_outline = 'icon-information_outline';
  static icon_delete_entry = 'icon-delete_entry';
  static icon_stopover_flag_add = 'icon-stopover_flag_add';
  static icon_air_distribution_left = 'icon-air_distribution_left';
  static icon_circle_filled = 'icon-circle_filled';
  static icon_ac_eco = 'icon-ac_eco';
  static icon_ac = 'icon-ac';
  static icon_air_off = 'icon-air_off';
  static icon_circle_outline = 'icon-circle_outline';
  static icon_setting = 'icon-setting';
  static icon_map_layer = 'icon-map_layer';
  static icon_air_distance_filled = 'icon-air_distance_filled';
  static icon_concierge = 'icon-concierge';
  static icon_air_distance_to_poi = 'icon-air_distance_to_poi';
  static icon_last_destination = 'icon-last_destination';
  static icon_address_home = 'icon-address_home';
  static icon_address_work = 'icon-address_work';
  static icon_bin = 'icon-bin';
  static icon_edit = 'icon-edit';
  static icon_channel_no_signal = 'icon-channel_no_signal';
  static icon_ipod = 'icon-ipod';
  static icon_fm_dab_no_signal = 'icon-fm_dab_no_signal';
  static icon_fm_dab = 'icon-fm_dab';
  static icon_fm_radio_no_signal = 'icon-fm_radio_no_signal';
  static icon_fm_radio = 'icon-fm_radio';
  static icon_sirius_xm_no_signal = 'icon-sirius_xm_no_signal';
  static icon_sirius_xm = 'icon-sirius_xm';
  static icon_epg = 'icon-epg';
  static icon_folder = 'icon-folder';
  static icon_fm_linking = 'icon-fm_linking';
  static icon_online_linking = 'icon-online_linking';
  static icon_fast_backward = 'icon-fast_backward';
  static icon_fast_forward = 'icon-fast_forward';
  static icon_car_limousine_auto_recirculation = 'icon-car_limousine_auto_recirculation';
  static icon_repeat_title = 'icon-repeat_title';
  static icon_podcast = 'icon-podcast';
  static icon_warning_filled = 'icon-warning_filled';
  static icon_aca_lks = 'icon-aca_lks';
  static icon_back_out_assistant = 'icon-back_out_assistant';
  static icon_cross_over_assistant = 'icon-cross_over_assistant';
  static icon_lane_change_assist = 'icon-lane_change_assist';
  static icon_exit_warning = 'icon-exit_warning';
  static icon_traffic_assist_j1 = 'icon-traffic_assist_j1';
  static icon_emergency_assist_j1 = 'icon-emergency_assist_j1';
  static icon_arrow_open_up = 'icon-arrow_open_up';
  static icon_arrow_open_down = 'icon-arrow_open_down';
  static icon_route = 'icon-route';
  static icon_seat_right = 'icon-seat_right';
  static icon_seat_left = 'icon-seat_left';
  static icon_seat_ventilation_right = 'icon-seat_ventilation_right';
  static icon_seat_ventilation_left = 'icon-seat_ventilation_left';
  static icon_phone_receiver_green = 'icon-phone_receiver_green';
  static icon_message_outline = 'icon-message_outline';
  static icon_lane_departure_warning_system_j1 = 'icon-lane_departure_warning_system_j1';
  static icon_emergency_assist = 'icon-emergency_assist';
  static icon_air_center_j1 = 'icon-air_center_j1';
  static icon_seat_heating_right = 'icon-seat_heating_right';
  static icon_seat_heating_left = 'icon-seat_heating_left';
  static icon_road = 'icon-road';
  static icon_freeway = 'icon-freeway';
  static icon_hov_avoid_not = 'icon-hov_avoid_not';
  static icon_freeway_toll = 'icon-freeway_toll';
  static icon_avoid_ferry_car_train_not = 'icon-avoid_ferry_car_train_not';
  static icon_tunnel = 'icon-tunnel';
  static icon_altitude = 'icon-altitude';
  static icon_message_draft = 'icon-message_draft';
  static icon_message_inbox = 'icon-message_inbox';
  static icon_message_outbox = 'icon-message_outbox';
  static icon_message_sent = 'icon-message_sent';
  static icon_message_template = 'icon-message_template';
  static icon_ass_basic = 'icon-ass_basic';
  static icon_options_more = 'icon-options_more';
  static icon_ass_j1 = 'icon-ass_j1';
  static icon_pdc_mute = 'icon-pdc_mute';
  static icon_on_off = 'icon-on_off';
  static icon_4G = 'icon-4G';
  static icon_predictive_maintenance = 'icon-predictive_maintenance';
  static icon_speller_coordinates = 'icon-speller_coordinates';
  static icon_warning_sign = 'icon-warning_sign';
  static icon_poi = 'icon-poi';
  static icon_stopover_flag = 'icon-stopover_flag';
  static icon_night_vision = 'icon-night_vision';
  static icon_update_center = 'icon-update_center';
  static icon_air_style_right = 'icon-air_style_right';
  static icon_phone_receiver = 'icon-phone_receiver';
  static icon_electricity = 'icon-electricity';
  static icon_contacts = 'icon-contacts';
  static icon_wind = 'icon-wind';
  static icon_sun = 'icon-sun';
  static icon_rain = 'icon-rain';
  static icon_trailer_view = 'icon-trailer_view';
  static icon_ipa = 'icon-ipa';
  static icon_rtv_panorama_view = 'icon-rtv_panorama_view';
  static icon_maneuver_assist_j1 = 'icon-maneuver_assist_j1';
  static icon_cleaning_camera = 'icon-cleaning_camera';
  static icon_camera_arrow = 'icon-camera_arrow';
  static icon_message_write = 'icon-message_write';
  static icon_destination_flag_stop = 'icon-destination_flag_stop';
  static icon_stopover_flag_delete = 'icon-stopover_flag_delete';
  static icon_ambient_light = 'icon-ambient_light';
  static icon_four_squares = 'icon-four_squares';
  static icon_cell_phone_link = 'icon-cell_phone_link';
  static icon_porsche_connect_app = 'icon-porsche_connect_app';
  static icon_maneuver_assist_j1_off = 'icon-maneuver_assist_j1_off';
  static icon_ipa_scanner = 'icon-ipa_scanner';
  static icon_trip_continuous = 'icon-trip_continuous';
  static icon_trip_personal = 'icon-trip_personal';
  static icon_trip_since_refuelling = 'icon-trip_since_refuelling';
  static icon_trip_since = 'icon-trip_since';
  static icon_PoweredByGracenote_Logo = 'icon-PoweredByGracenote_Logo';
  static icon_radionet_Logo = 'icon-radionet_Logo';
  static icon_circle = 'icon-circle';
  static icon_camera = 'icon-camera';
  static icon_indicator_warning = 'icon-indicator_warning';
  static icon_arrow_up = 'icon-arrow_up';
  static icon_update = 'icon-update';
  static icon_comma_fullstop_hiragana = 'icon-comma_fullstop_hiragana';
  static icon_hiragana_function_key = 'icon-hiragana_function_key';
  static icon_poi_filled = 'icon-poi_filled';
  static icon_apple_icloud = 'icon-apple_icloud';
  static icon_split_calls = 'icon-split_calls';
  static icon_more = 'icon-more';
  static icon_ac_fan_only = 'icon-ac_fan_only';
  static icon_addressbook = 'icon-addressbook';
  static icon_all = 'icon-all';
  static icon_all_outline = 'icon-all_outline';
  static icon_asterisk = 'icon-asterisk';
  static icon_asterisk_asia = 'icon-asterisk_asia';
  static icon_bubble_info = 'icon-bubble_info';
  static icon_communication = 'icon-communication';
  static icon_comma_fullstop_taiwan = 'icon-comma_fullstop_taiwan';
  static icon_comma_point_hiragana = 'icon-comma_point_hiragana';
  static icon_logo_sirius_xm_long = 'icon-logo_sirius_xm_long';
  static icon_logo_sirius_xm_short = 'icon-logo_sirius_xm_short';
  static icon_maximize = 'icon-maximize';
  static icon_minimize = 'icon-minimize';
  static icon_parking_cross_backward_left = 'icon-parking_cross_backward_left';
  static icon_parking_cross_backward_right = 'icon-parking_cross_backward_right';
  static icon_parking_cross_forward_left = 'icon-parking_cross_forward_left';
  static icon_parking_cross_forward_right = 'icon-parking_cross_forward_right';
  static icon_parking_garage = 'icon-parking_garage';
  static icon_parking_parallel_left = 'icon-parking_parallel_left';
  static icon_parking_parallel_right = 'icon-parking_parallel_right';
  static icon_ipa_search_right = 'icon-ipa_search_right';
  static icon_ipa_search_left = 'icon-ipa_search_left';
  static icon_genre = 'icon-genre';
  static icon_family = 'icon-family';
  static icon_knowledge = 'icon-knowledge';
  static icon_festival = 'icon-festival';
  static icon_list = 'icon-list';
  static icon_bookmark = 'icon-bookmark';
  static icon_themes = 'icon-themes';
  static icon_date_weekend = 'icon-date_weekend';
  static icon_date_today = 'icon-date_today';
  static icon_date_day = 'icon-date_day';
  static icon_check = 'icon-check';
  static icon_plus = 'icon-plus';
  static icon_air_distribution_diffuse = 'icon-air_distribution_diffuse';
  static icon_air_distribution_focus = 'icon-air_distribution_focus';
  static icon_pid_upcoming_events_right_curve = 'icon-pid_upcoming_events_right_curve';
  static icon_pid_upcoming_events_branch_off_left = 'icon-pid_upcoming_events_branch_off_left';
  static icon_pid_upcoming_events_branch_off_right = 'icon-pid_upcoming_events_branch_off_right';
  static icon_pid_upcoming_events_highway_exit_left = 'icon-pid_upcoming_events_highway_exit_left';
  static icon_pid_upcoming_events_highway_exit_right = 'icon-pid_upcoming_events_highway_exit_right';
  static icon_pid_upcoming_events_junction = 'icon-pid_upcoming_events_junction';
  static icon_pid_upcoming_events_left_curve = 'icon-pid_upcoming_events_left_curve';
  static icon_pid_upcoming_events_priority_to_left_a = 'icon-pid_upcoming_events_priority_to_left_a';
  static icon_pid_upcoming_events_priority_to_left_b = 'icon-pid_upcoming_events_priority_to_left_b';
  static icon_pid_upcoming_events_priority_to_right_a = 'icon-pid_upcoming_events_priority_to_right_a';
  static icon_pid_upcoming_events_priority_to_right_b = 'icon-pid_upcoming_events_priority_to_right_b';
  static icon_other_road = 'icon-other_road';
  static icon_bypass = 'icon-bypass';
  static icon_ass_disabled_j1 = 'icon-ass_disabled_j1';
  static icon_recuperation = 'icon-recuperation';
  static icon_clock_time = 'icon-clock_time';
  static icon_update_save = 'icon-update_save';
  static icon_arrow_down = 'icon-arrow_down';
  static icon_climate = 'icon-climate';
  static icon_ipa_leave_left = 'icon-ipa_leave_left';
  static icon_ipa_leave_right = 'icon-ipa_leave_right';
  static icon_logo_radio_net_long = 'icon-logo_radio_net_long';
  static icon_drag_vertical = 'icon-drag_vertical';
  static icon_road_side_assistance = 'icon-road_side_assistance';
  static icon_air_distribution_left_down = 'icon-air_distribution_left_down';
  static icon_air_distribution_left_mid = 'icon-air_distribution_left_mid';
  static icon_air_distribution_left_up = 'icon-air_distribution_left_up';
  static icon_air_distribution_right_down = 'icon-air_distribution_right_down';
  static icon_air_distribution_right_mid = 'icon-air_distribution_right_mid';
  static icon_air_distribution_right_up = 'icon-air_distribution_right_up';
  static icon_air_style_left_3 = 'icon-air_style_left_3';
  static icon_air_style_left_2 = 'icon-air_style_left_2';
  static icon_air_style_left_1 = 'icon-air_style_left_1';
  static icon_air_style_right_3 = 'icon-air_style_right_3';
  static icon_air_style_right_2 = 'icon-air_style_right_2';
  static icon_air_style_right_1 = 'icon-air_style_right_1';
  static icon_destination_flag_left = 'icon-destination_flag_left';
  static icon_destination_flag_offroad = 'icon-destination_flag_offroad';
  static icon_destination_flag_right = 'icon-destination_flag_right';
  static icon_stopover_flag_left = 'icon-stopover_flag_left';
  static icon_stopover_flag_offroad = 'icon-stopover_flag_offroad';
  static icon_stopover_flag_right = 'icon-stopover_flag_right';
  static icon_ac_max = 'icon-ac_max';
  static icon_conference_call = 'icon-conference_call';
  static icon_scale_zoom_stopover = 'icon-scale_zoom_stopover';
  static icon_scale_zoom_destination = 'icon-scale_zoom_destination';
  static icon_air_distribution_right = 'icon-air_distribution_right';
  static icon_saved = 'icon-saved';
  static icon_pilopa = 'icon-pilopa';
  static icon_logo_bose = 'icon-logo_bose';
  static icon_logo_burmester = 'icon-logo_burmester';
  static icon_notification_extended = 'icon-notification_extended';
  static icon_lg_dr_e0 = 'icon-lg_dr_e0';
  static icon_lg_dr_c0 = 'icon-lg_dr_c0';
  static icon_lg_dr_a0 = 'icon-lg_dr_a0';
  static icon_lg_dr_92 = 'icon-lg_dr_92';
  static icon_lg_dr_72 = 'icon-lg_dr_72';
  static icon_lg_dr_60 = 'icon-lg_dr_60';
  static icon_lg_dr_40 = 'icon-lg_dr_40';
  static icon_lg_dr_20 = 'icon-lg_dr_20';
  static icon_lg_dr_00 = 'icon-lg_dr_00';
  static icon_lg_2s_dr_e0_mitte = 'icon-lg_2s_dr_e0_mitte';
  static icon_lg_2s_dr_e0_links_lang = 'icon-lg_2s_dr_e0_links_lang';
  static icon_lg_2s_dr_e0_links_kurz = 'icon-lg_2s_dr_e0_links_kurz';
  static icon_lg_2s_dr_c0_mitte = 'icon-lg_2s_dr_c0_mitte';
  static icon_lg_2s_dr_c0_mitte_kurz = 'icon-lg_2s_dr_c0_mitte_kurz';
  static icon_lg_2s_dr_c0_links_lang = 'icon-lg_2s_dr_c0_links_lang';
  static icon_lg_2s_dr_c0_links_kurz = 'icon-lg_2s_dr_c0_links_kurz';
  static icon_lg_2s_dr_a0_mitte = 'icon-lg_2s_dr_a0_mitte';
  static icon_lg_2s_dr_a0_mitte_kurz = 'icon-lg_2s_dr_a0_mitte_kurz';
  static icon_lg_2s_dr_a0_links_lang = 'icon-lg_2s_dr_a0_links_lang';
  static icon_lg_2s_dr_a0_links_kurz = 'icon-lg_2s_dr_a0_links_kurz';
  static icon_lg_2s_dr_92_mitte = 'icon-lg_2s_dr_92_mitte';
  static icon_lg_2s_dr_92_links = 'icon-lg_2s_dr_92_links';
  static icon_lg_2s_dr_72_rechts = 'icon-lg_2s_dr_72_rechts';
  static icon_lg_2s_dr_72_mitte = 'icon-lg_2s_dr_72_mitte';
  static icon_lg_2s_dr_60_rechts_lang = 'icon-lg_2s_dr_60_rechts_lang';
  static icon_lg_2s_dr_60_rechts_kurz = 'icon-lg_2s_dr_60_rechts_kurz';
  static icon_lg_2s_dr_60_mitte = 'icon-lg_2s_dr_60_mitte';
  static icon_lg_2s_dr_60_mitte_kurz = 'icon-lg_2s_dr_60_mitte_kurz';
  static icon_lg_2s_dr_40_rechts_lang = 'icon-lg_2s_dr_40_rechts_lang';
  static icon_lg_2s_dr_40_rechts_kurz = 'icon-lg_2s_dr_40_rechts_kurz';
  static icon_lg_2s_dr_40_mitte = 'icon-lg_2s_dr_40_mitte';
  static icon_lg_2s_dr_40_mitte_kurz = 'icon-lg_2s_dr_40_mitte_kurz';
  static icon_lg_2s_dr_20_rechts_lang = 'icon-lg_2s_dr_20_rechts_lang';
  static icon_lg_2s_dr_20_rechts_kurz = 'icon-lg_2s_dr_20_rechts_kurz';
  static icon_lg_2s_dr_20_mitte = 'icon-lg_2s_dr_20_mitte';
  static icon_lg_2s_dr_00_rechts = 'icon-lg_2s_dr_00_rechts';
  static icon_lg_2s_dr_00_links = 'icon-lg_2s_dr_00_links';
  static icon_lg_3s_dr_e0_mitte = 'icon-lg_3s_dr_e0_mitte';
  static icon_lg_3s_dr_e0_mitte_lang = 'icon-lg_3s_dr_e0_mitte_lang';
  static icon_lg_3s_dr_e0_links = 'icon-lg_3s_dr_e0_links';
  static icon_lg_3s_dr_e0_links_lang = 'icon-lg_3s_dr_e0_links_lang';
  static icon_lg_3s_dr_c0_mitte_lang = 'icon-lg_3s_dr_c0_mitte_lang';
  static icon_lg_3s_dr_c0_mitte_kurz = 'icon-lg_3s_dr_c0_mitte_kurz';
  static icon_lg_3s_dr_c0_links = 'icon-lg_3s_dr_c0_links';
  static icon_lg_3s_dr_c0_links_lang = 'icon-lg_3s_dr_c0_links_lang';
  static icon_lg_3s_dr_c0_links_kurz = 'icon-lg_3s_dr_c0_links_kurz';
  static icon_lg_3s_dr_a0_mitte = 'icon-lg_3s_dr_a0_mitte';
  static icon_lg_3s_dr_a0_mitte_lang = 'icon-lg_3s_dr_a0_mitte_lang';
  static icon_lg_3s_dr_a0_mitte_kurz = 'icon-lg_3s_dr_a0_mitte_kurz';
  static icon_lg_3s_dr_a0_links = 'icon-lg_3s_dr_a0_links';
  static icon_lg_3s_dr_a0_links_lang = 'icon-lg_3s_dr_a0_links_lang';
  static icon_lg_3s_dr_a0_links_kurz = 'icon-lg_3s_dr_a0_links_kurz';
  static icon_lg_3s_dr_92_mitte = 'icon-lg_3s_dr_92_mitte';
  static icon_lg_3s_dr_92_mitte_kurz = 'icon-lg_3s_dr_92_mitte_kurz';
  static icon_lg_3s_dr_92_links = 'icon-lg_3s_dr_92_links';
  static icon_lg_3s_dr_92_links_kurz = 'icon-lg_3s_dr_92_links_kurz';
  static icon_lg_3s_dr_72_rechts = 'icon-lg_3s_dr_72_rechts';
  static icon_lg_3s_dr_72_rechts_kurz = 'icon-lg_3s_dr_72_rechts_kurz';
  static icon_lg_3s_dr_72_mitte = 'icon-lg_3s_dr_72_mitte';
  static icon_lg_3s_dr_72_mitte_kurz = 'icon-lg_3s_dr_72_mitte_kurz';
  static icon_lg_3s_dr_60_rechts = 'icon-lg_3s_dr_60_rechts';
  static icon_lg_3s_dr_60_rechts_lang = 'icon-lg_3s_dr_60_rechts_lang';
  static icon_lg_3s_dr_60_rechts_kurz = 'icon-lg_3s_dr_60_rechts_kurz';
  static icon_lg_3s_dr_60_mitte = 'icon-lg_3s_dr_60_mitte';
  static icon_lg_3s_dr_60_mitte_lang = 'icon-lg_3s_dr_60_mitte_lang';
  static icon_lg_3s_dr_60_mitte_kurz = 'icon-lg_3s_dr_60_mitte_kurz';
  static icon_lg_3s_dr_40_rechts = 'icon-lg_3s_dr_40_rechts';
  static icon_lg_3s_dr_40_rechts_lang = 'icon-lg_3s_dr_40_rechts_lang';
  static icon_lg_3s_dr_40_rechts_kurz = 'icon-lg_3s_dr_40_rechts_kurz';
  static icon_lg_3s_dr_40_mitte_lang = 'icon-lg_3s_dr_40_mitte_lang';
  static icon_lg_3s_dr_40_mitte_kurz = 'icon-lg_3s_dr_40_mitte_kurz';
  static icon_lg_3s_dr_20_rechts = 'icon-lg_3s_dr_20_rechts';
  static icon_lg_3s_dr_20_rechts_lang = 'icon-lg_3s_dr_20_rechts_lang';
  static icon_lg_3s_dr_20_mitte = 'icon-lg_3s_dr_20_mitte';
  static icon_lg_3s_dr_20_mitte_lang = 'icon-lg_3s_dr_20_mitte_lang';
  static icon_lg_3s_dr_00_rechts = 'icon-lg_3s_dr_00_rechts';
  static icon_lg_3s_dr_00_mitte = 'icon-lg_3s_dr_00_mitte';
  static icon_lg_3s_dr_00_links = 'icon-lg_3s_dr_00_links';
  static icon_lg_special_lane_widget_points = 'icon-lg_special_lane_widget_points';
  static icon_lg_special_buslane = 'icon-lg_special_buslane';
  static icon_04_base_layer_RoundAbout = 'icon-04_base_layer_RoundAbout';
  static icon_04_00_p_TurnArrow_UTurnC0 = 'icon-04_00_p_TurnArrow_UTurnC0';
  static icon_04_00_p_TurnArrow_UTurn40 = 'icon-04_00_p_TurnArrow_UTurn40';
  static icon_04_00_p_TurnArrow_StandardTurnE0 = 'icon-04_00_p_TurnArrow_StandardTurnE0';
  static icon_04_00_p_TurnArrow_StandardTurnC0 = 'icon-04_00_p_TurnArrow_StandardTurnC0';
  static icon_04_00_p_TurnArrow_StandardTurnA0 = 'icon-04_00_p_TurnArrow_StandardTurnA0';
  static icon_04_00_p_TurnArrow_StandardTurn60 = 'icon-04_00_p_TurnArrow_StandardTurn60';
  static icon_04_00_p_TurnArrow_StandardTurn40 = 'icon-04_00_p_TurnArrow_StandardTurn40';
  static icon_04_00_p_TurnArrow_StandardTurn20 = 'icon-04_00_p_TurnArrow_StandardTurn20';
  static icon_04_00_p_TurnArrow_StandardTurn00 = 'icon-04_00_p_TurnArrow_StandardTurn00';
  static icon_04_00_p_TurnArrow_SquareTrsRightF0 = 'icon-04_00_p_TurnArrow_SquareTrsRightF0';
  static icon_04_00_p_TurnArrow_SquareTrsRightE0 = 'icon-04_00_p_TurnArrow_SquareTrsRightE0';
  static icon_04_00_p_TurnArrow_SquareTrsRightB0_D0 = 'icon-04_00_p_TurnArrow_SquareTrsRightB0_D0';
  static icon_04_00_p_TurnArrow_SquareTrsRight90_A0 = 'icon-04_00_p_TurnArrow_SquareTrsRight90_A0';
  static icon_04_00_p_TurnArrow_SquareTrsRight80 = 'icon-04_00_p_TurnArrow_SquareTrsRight80';
  static icon_04_00_p_TurnArrow_SquareTrsRight60_70 = 'icon-04_00_p_TurnArrow_SquareTrsRight60_70';
  static icon_04_00_p_TurnArrow_SquareTrsRight30_50 = 'icon-04_00_p_TurnArrow_SquareTrsRight30_50';
  static icon_04_00_p_TurnArrow_SquareTrsRight20 = 'icon-04_00_p_TurnArrow_SquareTrsRight20';
  static icon_04_00_p_TurnArrow_SquareTrsRight10 = 'icon-04_00_p_TurnArrow_SquareTrsRight10';
  static icon_04_00_p_TurnArrow_SquareTrsRight00 = 'icon-04_00_p_TurnArrow_SquareTrsRight00';
  static icon_04_00_p_TurnArrow_SquareTrsLeftF0 = 'icon-04_00_p_TurnArrow_SquareTrsLeftF0';
  static icon_04_00_p_TurnArrow_SquareTrsLeftE0 = 'icon-04_00_p_TurnArrow_SquareTrsLeftE0';
  static icon_04_00_p_TurnArrow_SquareTrsLeftB0_D0 = 'icon-04_00_p_TurnArrow_SquareTrsLeftB0_D0';
  static icon_04_00_p_TurnArrow_SquareTrsLeft90_A0 = 'icon-04_00_p_TurnArrow_SquareTrsLeft90_A0';
  static icon_04_00_p_TurnArrow_SquareTrsLeft80 = 'icon-04_00_p_TurnArrow_SquareTrsLeft80';
  static icon_04_00_p_TurnArrow_SquareTrsLeft60_70 = 'icon-04_00_p_TurnArrow_SquareTrsLeft60_70';
  static icon_04_00_p_TurnArrow_SquareTrsLeft30_50 = 'icon-04_00_p_TurnArrow_SquareTrsLeft30_50';
  static icon_04_00_p_TurnArrow_SquareTrsLeft20 = 'icon-04_00_p_TurnArrow_SquareTrsLeft20';
  static icon_04_00_p_TurnArrow_SquareTrsLeft10 = 'icon-04_00_p_TurnArrow_SquareTrsLeft10';
  static icon_04_00_p_TurnArrow_SquareTrsLeft00 = 'icon-04_00_p_TurnArrow_SquareTrsLeft00';
  static icon_04_00_p_TurnArrow_ServiceRoadRight00 = 'icon-04_00_p_TurnArrow_ServiceRoadRight00';
  static icon_04_00_p_TurnArrow_ServiceRoadLeft00 = 'icon-04_00_p_TurnArrow_ServiceRoadLeft00';
  static icon_04_00_p_TurnArrow_RoundAboutTrsRightD0_F0 = 'icon-04_00_p_TurnArrow_RoundAboutTrsRightD0_F0';
  static icon_04_00_p_TurnArrow_RoundAboutTrsRightC0 = 'icon-04_00_p_TurnArrow_RoundAboutTrsRightC0';
  static icon_04_00_p_TurnArrow_RoundAboutTrsRight90_B0 = 'icon-04_00_p_TurnArrow_RoundAboutTrsRight90_B0';
  static icon_04_00_p_TurnArrow_RoundAboutTrsRight80 = 'icon-04_00_p_TurnArrow_RoundAboutTrsRight80';
  static icon_04_00_p_TurnArrow_RoundAboutTrsRight50_70 = 'icon-04_00_p_TurnArrow_RoundAboutTrsRight50_70';
  static icon_04_00_p_TurnArrow_RoundAboutTrsRight40 = 'icon-04_00_p_TurnArrow_RoundAboutTrsRight40';
  static icon_04_00_p_TurnArrow_RoundAboutTrsRight10_30 = 'icon-04_00_p_TurnArrow_RoundAboutTrsRight10_30';
  static icon_04_00_p_TurnArrow_RoundAboutTrsRight00 = 'icon-04_00_p_TurnArrow_RoundAboutTrsRight00';
  static icon_04_00_p_TurnArrow_RoundAboutTrsLeftD0_F0 = 'icon-04_00_p_TurnArrow_RoundAboutTrsLeftD0_F0';
  static icon_04_00_p_TurnArrow_RoundAboutTrsLeftC0 = 'icon-04_00_p_TurnArrow_RoundAboutTrsLeftC0';
  static icon_04_00_p_TurnArrow_RoundAboutTrsLeft90_B0 = 'icon-04_00_p_TurnArrow_RoundAboutTrsLeft90_B0';
  static icon_04_00_p_TurnArrow_RoundAboutTrsLeft80 = 'icon-04_00_p_TurnArrow_RoundAboutTrsLeft80';
  static icon_04_00_p_TurnArrow_RoundAboutTrsLeft50_70 = 'icon-04_00_p_TurnArrow_RoundAboutTrsLeft50_70';
  static icon_04_00_p_TurnArrow_RoundAboutTrsLeft40 = 'icon-04_00_p_TurnArrow_RoundAboutTrsLeft40';
  static icon_04_00_p_TurnArrow_RoundAboutTrsLeft10_30 = 'icon-04_00_p_TurnArrow_RoundAboutTrsLeft10_30';
  static icon_04_00_p_TurnArrow_RoundAboutTrsLeft00 = 'icon-04_00_p_TurnArrow_RoundAboutTrsLeft00';
  static icon_04_00_p_TurnArrow_OffRoadD0_F0 = 'icon-04_00_p_TurnArrow_OffRoadD0_F0';
  static icon_04_00_p_TurnArrow_OffRoadC0 = 'icon-04_00_p_TurnArrow_OffRoadC0';
  static icon_04_00_p_TurnArrow_OffRoad90_B0 = 'icon-04_00_p_TurnArrow_OffRoad90_B0';
  static icon_04_00_p_TurnArrow_OffRoad80 = 'icon-04_00_p_TurnArrow_OffRoad80';
  static icon_04_00_p_TurnArrow_OffRoad50_70 = 'icon-04_00_p_TurnArrow_OffRoad50_70';
  static icon_04_00_p_TurnArrow_OffRoad40 = 'icon-04_00_p_TurnArrow_OffRoad40';
  static icon_04_00_p_TurnArrow_OffRoad10_30 = 'icon-04_00_p_TurnArrow_OffRoad10_30';
  static icon_04_00_p_TurnArrow_OffRoad00 = 'icon-04_00_p_TurnArrow_OffRoad00';
  static icon_04_00_p_TurnArrow_MichiganUTurnC0 = 'icon-04_00_p_TurnArrow_MichiganUTurnC0';
  static icon_04_00_p_TurnArrow_MichiganUTurn40 = 'icon-04_00_p_TurnArrow_MichiganUTurn40';
  static icon_04_00_p_TurnArrow_ForkC0 = 'icon-04_00_p_TurnArrow_ForkC0';
  static icon_04_00_p_TurnArrow_Fork40 = 'icon-04_00_p_TurnArrow_Fork40';
  static icon_04_00_p_TurnArrow_FollowStreetFF = 'icon-04_00_p_TurnArrow_FollowStreetFF';
  static icon_04_00_p_TurnArrow_FollowStreetC0 = 'icon-04_00_p_TurnArrow_FollowStreetC0';
  static icon_04_00_p_TurnArrow_FollowStreet40 = 'icon-04_00_p_TurnArrow_FollowStreet40';
  static icon_04_00_p_TurnArrow_ExitRightC0_E0 = 'icon-04_00_p_TurnArrow_ExitRightC0_E0';
  static icon_04_00_p_TurnArrow_ExitRight00 = 'icon-04_00_p_TurnArrow_ExitRight00';
  static icon_04_00_p_TurnArrow_ExitLeftt20_40 = 'icon-04_00_p_TurnArrow_ExitLeftt20_40';
  static icon_04_00_p_TurnArrow_ExitLeft00 = 'icon-04_00_p_TurnArrow_ExitLeft00';
  static icon_04_00_p_TurnArrow_ChangeLaneC0 = 'icon-04_00_p_TurnArrow_ChangeLaneC0';
  static icon_04_00_p_TurnArrow_ChangeLane40 = 'icon-04_00_p_TurnArrow_ChangeLane40';
  static icon_arrow_open_full_right = 'icon-arrow_open_full_right';
  static icon_add_bluetooth = 'icon-add_bluetooth';
  static icon_source_bluetooth = 'icon-source_bluetooth';
  static icon_source_usb = 'icon-source_usb';
  static icon_logo_7eleven = 'icon-logo_7eleven';
  static icon_logo_afp = 'icon-logo_afp';
  static icon_logo_ansa = 'icon-logo_ansa';
  static icon_logo_autonavi = 'icon-logo_autonavi';
  static icon_logo_db_bahn = 'icon-logo_db_bahn';
  static icon_logo_deutsche_welle = 'icon-logo_deutsche_welle';
  static icon_logo_eventseeker = 'icon-logo_eventseeker';
  static icon_logo_flight_stats = 'icon-logo_flight_stats';
  static icon_logo_HeFeng = 'icon-logo_HeFeng';
  static icon_logo_inrix = 'icon-logo_inrix';
  static icon_logo_merian = 'icon-logo_merian';
  static icon_logo_meteoGroup = 'icon-logo_meteoGroup';
  static icon_logo_navitime = 'icon-logo_navitime';
  static icon_logo_npr = 'icon-logo_npr';
  static icon_logo_OpisNavx = 'icon-logo_OpisNavx';
  static icon_logo_pag = 'icon-logo_pag';
  static icon_logo_parkopedia = 'icon-logo_parkopedia';
  static icon_logo_rss = 'icon-logo_rss';
  static icon_logo_SID = 'icon-logo_SID';
  static icon_logo_Tagesschau = 'icon-logo_Tagesschau';
  static icon_logo_twitter = 'icon-logo_twitter';
  static icon_logo_wefind = 'icon-logo_wefind';
  static icon_logo_Xinhua = 'icon-logo_Xinhua';
  static icon_logo_yellowmap = 'icon-logo_yellowmap';
  static icon_arrow_open_full_left = 'icon-arrow_open_full_left';
  static icon_charge_timer = 'icon-charge_timer';
  static icon_pid_upcoming_events_traffic_jam_end = 'icon-pid_upcoming_events_traffic_jam_end';
  static icon_hd_voice = 'icon-hd_voice';
  static icon_logo_auro_3d = 'icon-logo_auro_3d';
  static icon_logo_powered_by_google = 'icon-logo_powered_by_google';
  static icon_b_call_premium = 'icon-b_call_premium';
  static icon_online_radio = 'icon-online_radio';
  static icon_video_image_fullscreen = 'icon-video_image_fullscreen';
  static icon_video_image_fullscreen_close = 'icon-video_image_fullscreen_close';
  static icon_easy_entry = 'icon-easy_entry';
  static icon_manual = 'icon-manual';
  static icon_tour_flag = 'icon-tour_flag';
  static icon_abs_failure = 'icon-abs_failure';
  static icon_abs_na = 'icon-abs_na';
  static icon_steering_wheel = 'icon-steering_wheel';
  static icon_acc = 'icon-acc';
  static icon_airbag_warning = 'icon-airbag_warning';
  static icon_voltage = 'icon-voltage';
  static icon_brake_worn_linings = 'icon-brake_worn_linings';
  static icon_brake_wear_na = 'icon-brake_wear_na';
  static icon_brake_failure = 'icon-brake_failure';
  static icon_brake_fluid_na = 'icon-brake_fluid_na';
  static icon_sos = 'icon-sos';
  static icon_parking_brake = 'icon-parking_brake';
  static icon_car_limousine_warning = 'icon-car_limousine_warning';
  static icon_esp_psm_off = 'icon-esp_psm_off';
  static icon_4WD = 'icon-4WD';
  static icon_car_limousine_outside_temp = 'icon-car_limousine_outside_temp';
  static icon_tempomat = 'icon-tempomat';
  static icon_seat_belt = 'icon-seat_belt';
  static icon_hal_1_2 = 'icon-hal_1_2';
  static icon_car_limousine_warning_hybrid = 'icon-car_limousine_warning_hybrid';
  static icon_pid = 'icon-pid';
  static icon_childlock = 'icon-childlock';
  static icon_engine_coolant_level = 'icon-engine_coolant_level';
  static icon_beam_levelling_control = 'icon-beam_levelling_control';
  static icon_lgi_traktion = 'icon-lgi_traktion';
  static icon_lgi_sicht = 'icon-lgi_sicht';
  static icon_lgi_unfall = 'icon-lgi_unfall';
  static icon_light_failure = 'icon-light_failure';
  static icon_traffic_sign_speed_limit_eu = 'icon-traffic_sign_speed_limit_eu';
  static icon_traffic_sign_speed_limit_nar = 'icon-traffic_sign_speed_limit_nar';
  static icon_traffic_sign_speed_limit_cn = 'icon-traffic_sign_speed_limit_cn';
  static icon_speed_limit_warning_vae = 'icon-speed_limit_warning_vae';
  static icon_traffic_sign_no_entry_eu = 'icon-traffic_sign_no_entry_eu';
  static icon_smart_key = 'icon-smart_key';
  static icon_traffic_sign_animal_eu = 'icon-traffic_sign_animal_eu';
  static icon_traffic_sign_pedestrians_eu = 'icon-traffic_sign_pedestrians_eu';
  static icon_pasm_warning = 'icon-pasm_warning';
  static icon_pdcc_j1 = 'icon-pdcc_j1';
  static icon_pos_lever = 'icon-pos_lever';
  static icon_traffic_sign_traffic_lights_free_right_turn_eu = 'icon-traffic_sign_traffic_lights_free_right_turn_eu';
  static icon_prioritysign_arrow_na = 'icon-prioritysign_arrow_na';
  static icon_traffic_sign_traffic_lights_no_turn_nar = 'icon-traffic_sign_traffic_lights_no_turn_nar';
  static icon_traffic_sign_give_way_eu = 'icon-traffic_sign_give_way_eu';
  static icon_prioritysign_roundabout = 'icon-prioritysign_roundabout';
  static icon_traffic_sign_stop_eu = 'icon-traffic_sign_stop_eu';
  static icon_traffic_sign_traffic_lights_eu = 'icon-traffic_sign_traffic_lights_eu';
  static icon_cross_lock = 'icon-cross_lock';
  static icon_warning_tire_pressure = 'icon-warning_tire_pressure';
  static icon_engine = 'icon-engine';
  static icon_e_range = 'icon-e_range';
  static icon_lock_locked = 'icon-lock_locked';
  static icon_key = 'icon-key';
  static icon_service = 'icon-service';
  static icon_voltage_high = 'icon-voltage_high';
  static icon_voltage_none = 'icon-voltage_none';
  static icon_coolant_temperature = 'icon-coolant_temperature';
  static icon_traffic_sign_recognition_j1 = 'icon-traffic_sign_recognition_j1';
  static icon_front_window_wiping_water = 'icon-front_window_wiping_water';
  static icon_lim = 'icon-lim';
  static icon_car_limousine_ready_cn = 'icon-car_limousine_ready_cn';
  static icon_electric_driving_mileage_of_hybrid_vehicles_mode_cn = 'icon-electric_driving_mileage_of_hybrid_vehicles_mode_cn';
  static icon_limited_performance_mode_power_cn = 'icon-limited_performance_mode_power_cn';
  static icon_limited_performance_mode_turtle_cn = 'icon-limited_performance_mode_turtle_cn';
  static icon_ev_charging_connector_lock_cn = 'icon-ev_charging_connector_lock_cn';
  static icon_car_limousine_system_failure_text_cn = 'icon-car_limousine_system_failure_text_cn';
  static icon_electric_motor_failure_cn = 'icon-electric_motor_failure_cn';
  static icon_sds_pickup_list_entry_1 = 'icon-sds_pickup_list_entry_1';
  static icon_sds_pickup_list_entry_2 = 'icon-sds_pickup_list_entry_2';
  static icon_sds_pickup_list_entry_3 = 'icon-sds_pickup_list_entry_3';
  static icon_sds_pickup_list_entry_4 = 'icon-sds_pickup_list_entry_4';
  static icon_propulsion_battery_temperature = 'icon-propulsion_battery_temperature';
  static icon_parking_light = 'icon-parking_light';
  static icon_plug = 'icon-plug';
  static icon_sidelights = 'icon-sidelights';
  static icon_door_lock_limousine = 'icon-door_lock_limousine';
  static icon_aca_hands_off = 'icon-aca_hands_off';
  static icon_fod = 'icon-fod';
  static icon_update_error = 'icon-update_error';
  static icon_lgi_panne = 'icon-lgi_panne';
  static icon_filler_cap_electric_warning_left = 'icon-filler_cap_electric_warning_left';
  static icon_filler_cap_electric_warning_right = 'icon-filler_cap_electric_warning_right';
  static icon_gforce_acceleration = 'icon-gforce_acceleration';
  static icon_gforce_delay = 'icon-gforce_delay';
  static icon_gforce_lateral_acceleration = 'icon-gforce_lateral_acceleration';
  static icon_filter = 'icon-filter';
  static icon_timer = 'icon-timer';
  static icon_soc_destination_0 = 'icon-soc_destination_0';
  static icon_soc_destination_1 = 'icon-soc_destination_1';
  static icon_soc_destination_2 = 'icon-soc_destination_2';
  static icon_soc_destination_3 = 'icon-soc_destination_3';
  static icon_soc_destination_4 = 'icon-soc_destination_4';
  static icon_soc_destination_5 = 'icon-soc_destination_5';
  static icon_soc_destination_blank = 'icon-soc_destination_blank';
  static icon_organizer = 'icon-organizer';
  static icon_logo_taycan = 'icon-logo_taycan';
  static icon_charging_inductive = 'icon-charging_inductive';
  static icon_homelink_send = 'icon-homelink_send';
  static icon_plim_offset = 'icon-plim_offset';
  static icon_plim_three_digits = 'icon-plim_three_digits';
  static icon_plim_two_digits = 'icon-plim_two_digits';
  static icon_poi_listing = 'icon-poi_listing';
  static icon_brake_key_failure = 'icon-brake_key_failure';
  static icon_charging_station_warning = 'icon-charging_station_warning';
  static icon_charging_station_warning_outline = 'icon-charging_station_warning_outline';
  static icon_logo_cityseeker = 'icon-logo_cityseeker';
  static icon_eco_plus = 'icon-eco_plus';
  static icon_update_security = 'icon-update_security';
  static icon_recuperation_level_adaptive = 'icon-recuperation_level_adaptive';
  static icon_assist_on_off = 'icon-assist_on_off';
  static icon_codriver_cockpit = 'icon-codriver_cockpit';
  static icon_charging_active = 'icon-charging_active';
  static icon_filler_cap_ac_left = 'icon-filler_cap_ac_left';
  static icon_filler_cap_ac_right = 'icon-filler_cap_ac_right';
  static icon_filler_cap_dc_left = 'icon-filler_cap_dc_left';
  static icon_filler_cap_dc_right = 'icon-filler_cap_dc_right';
  static icon_filler_cap_dcac_left = 'icon-filler_cap_dcac_left';
  static icon_filler_cap_dcac_right = 'icon-filler_cap_dcac_right';
  static icon_plug_left_ac_cn = 'icon-plug_left_ac_cn';
  static icon_plug_right_dc_cn = 'icon-plug_right_dc_cn';
  static icon_plug_short_left = 'icon-plug_short_left';
  static icon_plug_short_right = 'icon-plug_short_right';
  static icon_charge_premium = 'icon-charge_premium';
  static icon_parking_plus = 'icon-parking_plus';
  static icon_air_footwell_left_cold = 'icon-air_footwell_left_cold';
  static icon_air_footwell_left_warm = 'icon-air_footwell_left_warm';
  static icon_air_footwell_left = 'icon-air_footwell_left';
  static icon_air_footwell_right_cold = 'icon-air_footwell_right_cold';
  static icon_air_footwell_right_warm = 'icon-air_footwell_right_warm';
  static icon_air_footwell_right = 'icon-air_footwell_right';
  static icon_speak = 'icon-speak';
  static icon_message = 'icon-message';
  static icon_sim_card = 'icon-sim_card';
  static icon_homelink = 'icon-homelink';
  static icon_logo_qi = 'icon-logo_qi';
  static icon_duration = 'icon-duration';
  static icon_money = 'icon-money';
  static icon_seat_massage_strength_right = 'icon-seat_massage_strength_right';
  static icon_seat_massage_strength_left = 'icon-seat_massage_strength_left';
  static icon_seat_massage_strength_5_right = 'icon-seat_massage_strength_5_right';
  static icon_seat_massage_strength_5_left = 'icon-seat_massage_strength_5_left';
  static icon_seat_massage_strength_4_right = 'icon-seat_massage_strength_4_right';
  static icon_seat_massage_strength_4_left = 'icon-seat_massage_strength_4_left';
  static icon_seat_massage_strength_3_right = 'icon-seat_massage_strength_3_right';
  static icon_seat_massage_strength_3_left = 'icon-seat_massage_strength_3_left';
  static icon_seat_massage_strength_2_right = 'icon-seat_massage_strength_2_right';
  static icon_seat_massage_strength_2_left = 'icon-seat_massage_strength_2_left';
  static icon_seat_massage_strength_1_right = 'icon-seat_massage_strength_1_right';
  static icon_seat_massage_strength_1_left = 'icon-seat_massage_strength_1_left';
  static icon_seat_massage_right = 'icon-seat_massage_right';
  static icon_seat_massage_left = 'icon-seat_massage_left';
  static icon_e_ac = 'icon-e_ac';
  static icon_seat_comfort_codriver_left = 'icon-seat_comfort_codriver_left';
  static icon_seat_comfort_left = 'icon-seat_comfort_left';
  static icon_seat_config_codriver_left = 'icon-seat_config_codriver_left';
  static icon_seat_easy_entry_left = 'icon-seat_easy_entry_left';
  static icon_cityseeker_skyline = 'icon-cityseeker_skyline';
  static icon_person_login = 'icon-person_login';
  static icon_car_limousine_hood = 'icon-car_limousine_hood';
  static icon_car_sport_trunk_close_arrow = 'icon-car_sport_trunk_close_arrow';
  static icon_car_sport_trunk_open_arrow = 'icon-car_sport_trunk_open_arrow';
  static icon_plug_ccs_type2 = 'icon-plug_ccs_type2';
  static icon_plug_chademo = 'icon-plug_chademo';
  static icon_plug_iec_type1 = 'icon-plug_iec_type1';
  static icon_plug_iec_type2 = 'icon-plug_iec_type2';
  static icon_plug_ccs_type_1 = 'icon-plug_ccs_type_1';
  static icon_plug_gbt_20234_2_cn = 'icon-plug_gbt_20234_2_cn';
  static icon_plug_gbt_20234_3_cn = 'icon-plug_gbt_20234_3_cn';
  static icon_stopover_flag_left_with_lable = 'icon-stopover_flag_left_with_lable';
  static icon_stopover_flag_right_label = 'icon-stopover_flag_right_label';
  static icon_04_base_layer_Square = 'icon-04_base_layer_Square';
  static icon_home_sync = 'icon-home_sync';
  static icon_logo_yelp = 'icon-logo_yelp';
  static icon_phone_switch_call = 'icon-phone_switch_call';
  static icon_rating_cityseeker_0_5 = 'icon-rating_cityseeker_0_5';
  static icon_rating_cityseeker_0 = 'icon-rating_cityseeker_0';
  static icon_rating_cityseeker_1_5 = 'icon-rating_cityseeker_1_5';
  static icon_rating_cityseeker_1 = 'icon-rating_cityseeker_1';
  static icon_rating_cityseeker_2_5 = 'icon-rating_cityseeker_2_5';
  static icon_rating_cityseeker_2 = 'icon-rating_cityseeker_2';
  static icon_rating_cityseeker_3_5 = 'icon-rating_cityseeker_3_5';
  static icon_rating_cityseeker_3 = 'icon-rating_cityseeker_3';
  static icon_rating_cityseeker_3star_0_5 = 'icon-rating_cityseeker_3star_0_5';
  static icon_rating_cityseeker_3star_0 = 'icon-rating_cityseeker_3star_0';
  static icon_rating_cityseeker_3star_1_5 = 'icon-rating_cityseeker_3star_1_5';
  static icon_rating_cityseeker_3star_1 = 'icon-rating_cityseeker_3star_1';
  static icon_rating_cityseeker_3star_2_5 = 'icon-rating_cityseeker_3star_2_5';
  static icon_rating_cityseeker_3star_2 = 'icon-rating_cityseeker_3star_2';
  static icon_rating_cityseeker_3star_3 = 'icon-rating_cityseeker_3star_3';
  static icon_rating_cityseeker_4_5 = 'icon-rating_cityseeker_4_5';
  static icon_rating_cityseeker_4 = 'icon-rating_cityseeker_4';
  static icon_rating_cityseeker_5 = 'icon-rating_cityseeker_5';
  static icon_rating_yelp_0 = 'icon-rating_yelp_0';
  static icon_rating_yelp_1_5 = 'icon-rating_yelp_1_5';
  static icon_rating_yelp_1 = 'icon-rating_yelp_1';
  static icon_rating_yelp_2_5 = 'icon-rating_yelp_2_5';
  static icon_rating_yelp_2 = 'icon-rating_yelp_2';
  static icon_rating_yelp_3_5 = 'icon-rating_yelp_3_5';
  static icon_rating_yelp_3 = 'icon-rating_yelp_3';
  static icon_rating_yelp_4_5 = 'icon-rating_yelp_4_5';
  static icon_rating_yelp_4 = 'icon-rating_yelp_4';
  static icon_rating_yelp_5 = 'icon-rating_yelp_5';
  static icon_sxm_possession = 'icon-sxm_possession';
  static icon_parking_brake_na = 'icon-parking_brake_na';
  static icon_lgi_aquaplaning = 'icon-lgi_aquaplaning';
  static icon_traffic_antenna = 'icon-traffic_antenna';
  static icon_traffic_sign_danger_eu = 'icon-traffic_sign_danger_eu';
  static icon_logo_hd_radio = 'icon-logo_hd_radio';
  static icon_globe_na = 'icon-globe_na';
  static icon_news = 'icon-news';
  static icon_flight_info = 'icon-flight_info';
  static icon_city_events = 'icon-city_events';
  static icon_privacy_data = 'icon-privacy_data';
  static icon_privacy_data_tracking = 'icon-privacy_data_tracking';
  static icon_privacy_data_gps = 'icon-privacy_data_gps';
  static icon_data_tracking = 'icon-data_tracking';
  static icon_wheel = 'icon-wheel';
  static icon_arrow_n_button = 'icon-arrow_n_button';
  static icon_globe_small_na = 'icon-globe_small_na';
  static icon_globe_small = 'icon-globe_small';
  static icon_sxm_notification_outline = 'icon-sxm_notification_outline';
  static icon_sxm_for_you = 'icon-sxm_for_you';
  static icon_sxm_notification = 'icon-sxm_notification';
  static icon_sxm_on_demand = 'icon-sxm_on_demand';
  static icon_apple_add_to_library = 'icon-apple_add_to_library';
  static icon_apple_dislike = 'icon-apple_dislike';
  static icon_apple_for_you = 'icon-apple_for_you';
  static icon_apple_love = 'icon-apple_love';
  static icon_apple_radio = 'icon-apple_radio';
  static icon_skip_30s_backward = 'icon-skip_30s_backward';
  static icon_skip_30s_forward = 'icon-skip_30s_forward';
  static icon_logo_apple_music = 'icon-logo_apple_music';
  static icon_apple_remove_from_playlist = 'icon-apple_remove_from_playlist';
  static icon_apple_play_next = 'icon-apple_play_next';
  static icon_apple_play_later = 'icon-apple_play_later';
  static icon_apple_lyrics = 'icon-apple_lyrics';
  static icon_apple_library = 'icon-apple_library';
  static icon_apple_create_station = 'icon-apple_create_station';
  static icon_apple_add_to_playlist = 'icon-apple_add_to_playlist';
  static icon_qr_code_na = 'icon-qr_code_na';
  static icon_bubble_recommendations = 'icon-bubble_recommendations';
  static icon_charging_target_state = 'icon-charging_target_state';
  static icon_charging_profile = 'icon-charging_profile';
  static icon_swipe = 'icon-swipe';
  static icon_phone_ring_tone = 'icon-phone_ring_tone';
  static icon_parking_forward = 'icon-parking_forward';
  static icon_filler_cap_mechanic_warning_right = 'icon-filler_cap_mechanic_warning_right';
  static icon_filler_cap_mechanic_warning_left = 'icon-filler_cap_mechanic_warning_left';
  static icon_plug_small = 'icon-plug_small';
  static icon_logo_feedly = 'icon-logo_feedly';
  static icon_e_range_nav = 'icon-e_range_nav';
  static icon_box_checked_empty = 'icon-box_checked_empty';
  static icon_apple_explicit = 'icon-apple_explicit';
  static icon_rear_window_defrost = 'icon-rear_window_defrost';
  static icon_front_window_defrost = 'icon-front_window_defrost';
  static icon_skip_15s_backward = 'icon-skip_15s_backward';
  static icon_skip_15s_forward = 'icon-skip_15s_forward';
  static icon_traffic_sign_speed_limit_advisory_kmh_nar_can = 'icon-traffic_sign_speed_limit_advisory_kmh_nar_can';
  static icon_traffic_sign_speed_limit_kmh_nar_can = 'icon-traffic_sign_speed_limit_kmh_nar_can';
  static icon_e_range_cn = 'icon-e_range_cn';
  static icon_sxm_fast_backward = 'icon-sxm_fast_backward';
  static icon_sxm_fast_forward = 'icon-sxm_fast_forward';
  static icon_traffic_sign_speed_limit_eu_kmh = 'icon-traffic_sign_speed_limit_eu_kmh';
  static icon_traffic_sign_speed_limit_eu_mph = 'icon-traffic_sign_speed_limit_eu_mph';
  static icon_traffic_sign_speed_limit_generic_nar_can = 'icon-traffic_sign_speed_limit_generic_nar_can';
  static icon_lg_special_carpool = 'icon-lg_special_carpool';
  static icon_logo_apple_music_color = 'icon-logo_apple_music_color';
  static icon_connect = 'icon-connect';
  static icon_export = 'icon-export';
  static icon_news_channel = 'icon-news_channel';
  static icon_eye = 'icon-eye';
  static icon_eye_off = 'icon-eye_off';
  static icon_subject = 'icon-subject';
  static icon_homelink_send_1 = 'icon-homelink_send_1';
  static icon_homelink_send_2 = 'icon-homelink_send_2';
  static icon_homelink_send_3 = 'icon-homelink_send_3';
  static icon_trip = 'icon-trip';
  static icon_traffic_info_outline_ece = 'icon-traffic_info_outline_ece';
  static icon_traffic_info_outline_nar = 'icon-traffic_info_outline_nar';
  static icon_air = 'icon-air';
  static icon_base_mode_menu = 'icon-base_mode_menu';
  static icon_tune_keypads = 'icon-tune_keypads';
  static icon_powermeter = 'icon-powermeter';
  static icon_porsche_labs = 'icon-porsche_labs';
  static icon_lg_2s_dr_40_mitte_kurz_n = 'icon-lg_2s_dr_40_mitte_kurz_n';
  static icon_lg_2s_dr_60_mitte_n = 'icon-lg_2s_dr_60_mitte_n';
  static icon_lg_2s_dr_a0_mitte_n = 'icon-lg_2s_dr_a0_mitte_n';
  static icon_lg_2s_dr_c0_mitte_kurz_n = 'icon-lg_2s_dr_c0_mitte_kurz_n';
  static icon_lg_3s_dr_20_rechts_lang_n = 'icon-lg_3s_dr_20_rechts_lang_n';
  static icon_lg_3s_dr_20_rechts_n = 'icon-lg_3s_dr_20_rechts_n';
  static icon_lg_3s_dr_40_rechts_kurz_n = 'icon-lg_3s_dr_40_rechts_kurz_n';
  static icon_lg_3s_dr_40_rechts_lang_n = 'icon-lg_3s_dr_40_rechts_lang_n';
  static icon_lg_3s_dr_40_rechts_n = 'icon-lg_3s_dr_40_rechts_n';
  static icon_lg_3s_dr_c0_links_kurz_n = 'icon-lg_3s_dr_c0_links_kurz_n';
  static icon_lg_3s_dr_c0_links_lang_n = 'icon-lg_3s_dr_c0_links_lang_n';
  static icon_lg_3s_dr_c0_links_n = 'icon-lg_3s_dr_c0_links_n';
  static icon_lg_3s_dr_e0_links_lang_n = 'icon-lg_3s_dr_e0_links_lang_n';
  static icon_lg_3s_dr_e0_links_n = 'icon-lg_3s_dr_e0_links_n';
  static icon_lg_dr_40_n = 'icon-lg_dr_40_n';
  static icon_lg_dr_60_n = 'icon-lg_dr_60_n';
  static icon_lg_dr_a0_n = 'icon-lg_dr_a0_n';
  static icon_lg_dr_c0_n = 'icon-lg_dr_c0_n';
  static icon_option_view_1 = 'icon-option_view_1';
  // Webapp IconTypes
  static icon_app_img = 'big';
  static icon_app_svg = 'svg';
};
