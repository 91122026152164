import * as _ from "lodash"
import * as React from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { AppState } from "../components/redux/reducer"
import { SET_AUTHORIZED } from "../components/redux/types/mainState/types"
import Keypad from "./CopiedStuff/CopyKeypad"
import "./LoginKeyPad.scss"

const mapper = (state: AppState) => ({ authorized: state.mainState.authorized })

interface LoginKeyPadProps {
  authorized: boolean
  dispatch: Dispatch<any>
  root: JSX.Element
}

interface LoginKeyPadStats {
  code: string
  wrong?: boolean
}

const lngMap = {
  de: "de_DE",
  en: "en_GB",
  "en-gb": "en_GB",
  "en-us": "en_US",
  es: "es_ES",
  "zh-cn": "zh_CN",
  "zh-tw": "zh_TW",
  fr: "fr_FR",
  "fr-fr": "fr_FR",
  it: "it_IT",
  "it-it": "it_IT",
  jp: "ja_JP",
  "ja-jp": "ja_JP",
  kr: "ko_KR",
  "ko-kr": "ko_KR",
  nl: "nl_NL",
  "nl-nl": "nl_NL",
  br: "pt_BR",
  "pt-br": "pt_BR",
  ru: "ru_RU",
  "ru-ru": "ru_RU"
}

class LoginKeyPad extends React.PureComponent<LoginKeyPadProps, LoginKeyPadStats> {
  private ref?: HTMLDivElement
  public state: LoginKeyPadStats = {
    code: ""
  }
  public componentDidMount() {
    document.addEventListener("onresize", this.onResize)
  }

  public componentWillUnmount() {
    document.removeEventListener("onresize", this.onResize)
  }
  render() {
    const w = window.innerWidth
    const h = window.innerHeight
    const scale = Math.min(w < 360 ? w / 360 : 1.0, h > w ? (h < 834 ? h / 834 : 1.0) : 1.0)

    return (
      <div className="LoginKeyPad">
        {this.state.wrong && (
          <div className="LoginKeyPad-Error">
            The code you entered seems to be different from ours. Please check it again.
          </div>
        )}
        <div
          style={{
            width: 360,
            transform: `scale(${scale}) translateX(-50%)`,
            transformOrigin: "0% 0%",
            margin: "auto",
            position: "absolute",
            top: this.state.wrong ? 30 : 0,
            left: "50%"
          }}
        >
          <div className="LoginKeyPad-Container">
            <div className="LoginKeyPad-Icon">
              <img
                alt=""
                src="https://files.porsche.com/filestore/image/multimedia/none/875d5e3d-6763-47fe-bc52-809142d53274/svg/9bee0427-35f0-11ea-80c6-005056bbdc38/porsche-svg.svg"
              />
            </div>
            <div className="LoginKeyPad-Title">Enter code to access PCM simulation</div>.
          </div>
          <div className="LoginKeyPad-Container">
            <Keypad onClick={this.click} altLastRow />
          </div>
          <div className="LoginKeyPad-Container" style={{ padding: 15 }}>
            <div className="LoginKeyPad-InputField">
              <div className="LoginKeyPad-InputField-Text">{_.map(this.state.code, (c) => "*")}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private click = (text: string) => {
    switch (text) {
      case "#":
        this.setState({ code: "" })
        break
      case "<-":
        if (this.state.code.length > 0) this.setState({ code: this.state.code.substr(0, this.state.code.length - 1) })
        break
      case "send":
        if (this.state.code === "0711") {
          const locale = localStorage.getItem("locale")
          if (!locale) {
            const lang = (navigator as any).language || (navigator as any).userLanguage
            const split = lang.split("-")
            const ld = (lngMap as any)[lang]
            const l = ld ? ld : split.length > 1 ? (lngMap as any)[split[0]] : undefined
            localStorage.setItem("locale", l ? l : "en_GB")
          }
          this.props.dispatch({ type: SET_AUTHORIZED, payload: true })
        } else this.setState({ wrong: true, code: "" })
        break
      default:
        if (this.state.code.length < 8) this.setState({ code: this.state.code + text, wrong: false })
        break
    }
  }
  private onResize = () => {
    this.forceUpdate()
  }
}

export default connect(mapper)(LoginKeyPad)
