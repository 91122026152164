import axios, { AxiosRequestConfig, Method, ResponseType } from 'axios';
import * as _ from "lodash";

/*
helper class to rename all _links to links in the strings
received from the back-end
*/
type RequestType = "GET" | "PATCH" | "PUT" | "POST" | "DELETE" | "OPTIONS";
export interface ICallErrorResponse {
    errorReason: any;
    statusCode?: number;
}
export interface LinkType<T = RequestType> {
    ref: string;
    uri: string;
    method: T;
}



export interface ICallData {
    uri: string;
    method: RequestType;
    data?: any;
    pathElements?: string[];
    responseStatus?: number[];
    type?: string;
    headers?: any;
    onProgress?: (cur: number, total: number) => void;
}

export interface ILoadParams<ReturnType> extends ICallData {
    mutex?: string;
    onSuccess?: (value: ReturnType) => void;
    onError?: (reason: ICallErrorResponse) => void;
    errorResponseCodeMap?: { [key: number]: () => void };
}

export class BaseClient {

    constructor(private _baseUrl: string) {
        this._mutexes = {};
        this._errorMap = {};
        let a: any = (window as any).webkit;
        if (a)
            this._webkit = a.messageHandlers.axios;
    }
    private _webkit: any;
    private _token: string = "";
    private _debug = false;
    private _mutexes: { [key: string]: boolean };
    private _errorMap: { [key: number]: () => void };

    /*
    Helper method to get data from the back-end using the client class and
    call the dispatcher on a CONNECTED react component.
  
    parameters:
      component     A react component with properties including "IConnectedComponent" props
                    (this includes dispatcher and state)
      promise       The result of a client call i.e. getUsers or something like that
      action        Action to dispatch after successfully get data from the back-end.
    */

    public setErrorHandler(code: number, func: () => void) {
        this._errorMap[code] = func;
    }

    public get baseUrl(): string {
        return this._baseUrl;
    }

    public set debug(val: boolean) {
        this._debug = val;
    }

    public lockMutex(name: string | undefined) {
        if (!name)
            return true;
        if (this._mutexes[name])
            return false;
        this._mutexes[name] = true;
        return true;
    }

    public unlockMutex(name: string | undefined) {
        if (!name)
            return true;
        if (!this._mutexes[name])
            return false;
        this._mutexes[name] = false;
        return true;
    }

    public checkMutex(name: string) {
        return this._mutexes !== undefined ? this._mutexes : false;
    }

    public get token(): string {
        return this._token;
    }
    public set token(value: string) {
        this._token = value;
    }

    isAuthenicated(): boolean {
        return this.token !== "";
    }

    public canExecute(links: Array<LinkType<string>>, ref: string): LinkType | undefined {
        return _.find(links, link => link.ref === ref) as LinkType;
    }
    public login(uri: string, data: { login: string, password: string }): Promise<string> {
        let toRet: Promise<string> = new Promise<string>(async (resolve, reject) => {
            const config: AxiosRequestConfig =
            {
                baseURL: this.baseUrl,
                url: uri,
                method: "POST",
                data: data,
                withCredentials: true
            };
            try {
                const response = await axios(config);
                if (response.status !== 200)
                    reject({ errorReason: response.status + " returned expected " + 200, statusCode: response.status });
                resolve(response.data);
            }
            catch (error) {
                reject({ errorReason: error });
            }
            const t: any = undefined;
            resolve(t);
        });
        return toRet;
    }

    public call<ReturnType = any>(params: ILoadParams<ReturnType>) {
        if (this._debug)
            console.log("params = ", params);
        if (this.lockMutex(params.mutex)) {
            let uri = params.uri;
            if (uri.startsWith("/"))
                uri = uri.substr(1);
            if (uri.endsWith("/"))
                uri = uri.substr(0, uri.length - 1);
            this.callMethod<ReturnType>({
                uri,
                method: params.method,
                data: params.data,
                pathElements: params.pathElements,
                responseStatus: params.responseStatus ? params.responseStatus : [200, 201],
                type: params.type ? params.type : "json",
                onProgress: params.onProgress
            })
                .then(
                    (val) => {
                        this.unlockMutex(params.mutex);
                        if (params.onSuccess)
                            params.onSuccess(val);
                    },
                    (reason: ICallErrorResponse) => {

                        if (reason.statusCode) {
                            if (params.errorResponseCodeMap && params.errorResponseCodeMap[reason.statusCode])
                                params.errorResponseCodeMap[reason.statusCode]();
                            if (this._errorMap[reason.statusCode])
                                this._errorMap[reason.statusCode]();
                        }
                        this.unlockMutex(params.mutex);
                        if (params.onError)
                            params.onError(reason);
                    }
                );
        }
    }

    public callMethod<T = any>(data: ICallData) {
        if (this._debug)
            console.log("data = ", data);
        let toRet: Promise<T> = new Promise<T>(async (resolve, reject) => {
            let path = "";
            if (data.pathElements)
                _.forEach(data.pathElements, v => path += "/" + v);
            const headers = Object.assign({},
                {
                    "X-ZUMO-AUTH": this.token ? this.token : undefined,
                    "X-Forwarded-Ssl": "on",
                    "Access-Control-Max-Age": 600
                },
                data.headers ? data.headers : {});
            const config: AxiosRequestConfig =
            {
                baseURL: this.baseUrl,
                url: data.uri + path,
                method: data.method as Method,
                responseType: (data.type ? data.type : "json") as ResponseType,
                data: data.data ? data.data : undefined,
                headers: headers,
                onUploadProgress: data.onProgress ? (ev: any) => {
                    if (data.onProgress)
                        data.onProgress(ev.loaded, ev.total);
                } : undefined
            };
            if (this._debug)
                console.log("config = ", config);
            if (this._webkit)
                this._webkit.postMessage({ type: "request", url: config.url, baseURL: config.baseURL, method: config.method });
            try {
                const response = await axios(config);
                const rcodes = data.responseStatus ? data.responseStatus : [200, 201];
                if (_.indexOf(rcodes, response.status) < 0) {
                    if (this._webkit)
                        this._webkit.postMessage({ type: "error", status: response.status, url: config.url, baseURL: config.baseURL, method: config.method });
                    reject({ errorReason: "unexpected return code " + response.status, statusCode: response.status });
                }
                if (this._webkit)
                    this._webkit.postMessage({ type: "response", data: response.data, url: config.url, baseURL: config.baseURL, method: config.method });
                resolve(response.data);
            }
            catch (error) {
                if (error.response) {
                    reject({ errorReason: error, statusCode: error.response.status, url: config.url, baseURL: config.baseURL, method: config.method });
                }
                else
                    reject({ errorReason: error });
            }
            const t: any = undefined;
            resolve(t);
        });
        return toRet;
    }

    public callLink<T = any>(links: { links: Array<LinkType<string>> } | Array<LinkType<string>>, refName: string = "self") {
        let l: LinkType | undefined;
        if (links.hasOwnProperty("links"))
            l = this.canExecute((links as any).links, refName);
        else
            l = this.canExecute(links as Array<LinkType<string>>, refName);
        if (!l)
            return new Promise<T>((resolve, reject) => reject("link not found!"));
        return this.execute<T>(l);
    }

    public execute<T = any>(link: { uri: string, method: RequestType }, params?: any, data?: any, pathElements?: string[], responseStatus: number = 200, type: string = "json"): Promise<T> {
        let toRet: Promise<T> = new Promise<T>(async (resolve, reject) => {
            let template = link.uri;
            if (params !== undefined) {
                _.forEach(params, (v, i) => {
                    const needle = "{" + i + "}";
                    if (template.indexOf(needle) === -1)
                        reject("template doesn't match passed values");
                    template = template.replace("{" + i + "}", v);
                });
            }
            this.call<T>({
                uri: template,
                method: link.method,
                data,
                pathElements,
                responseStatus: [responseStatus],
                type,
                onSuccess: (val: T) => resolve(val),
                onError: (reason: ICallErrorResponse) => reject(reason)
            });
        });
        return toRet;
    }
}


